.md-text-field-container {
  position: relative;
  display: flex;
  flex-direction: column;

  &.--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .md-text-field,
  .md-text-field--outlined {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    min-height: 36px;
  }

  .md-text-field-field-input {
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    caret-color: rgba(0, 0, 0, 1.0);
    color: rgba(0, 0, 0, 0.87);
    font-size: 1rem;
    font-weight: 300;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
      -moz-box-shadow: 0 0 0 100px #fff inset;
      box-shadow: 0 0 0 100px #fff inset;
    }

    // Font styles
    &:-webkit-autofill::first-line {
      font-family: inherit;
      font-size: 14px;
      font-weight: bold;
    }


  }

  .md-text-field-field-input:-webkit-autofill + .md-text-field-outline-container .md-text-field-label {
    transform: translateY(-17px) scale(0.85) !important;
  }

  .md-text-field-field-input:-webkit-autofill + .md-text-field-outline-container .md-text-field-outline-container-middle {
    border-top: none;
  }

  .md-text-field-outline-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    text-align: left;
    pointer-events: none;

    .md-text-field-outline-container-left {
      border-top-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 4px;

      width: 12px;
      border-left: 1px solid;
      border-right: none;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.38);
    }

    .md-text-field-outline-container-middle {
      display: flex;
      align-items: center;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.38);
      flex: 0 0 auto;
      width: auto;

      .md-text-field-label {
        pointer-events: none;
        display: inline-block;
        position: relative;
        max-width: 100%;
        color: rgba(0, 0, 0, 0.38);
        font-size: 13px;
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
      }
    }

    .md-text-field-outline-container-right {
      border-top-left-radius: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 0;
      flex-grow: 1;
      border-left: none;
      border-right: 1px solid;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.38);
    }
  }

  .md-text-field-helper-line {
    padding-right: 16px;
    padding-left: 16px;
  }

  .md-text-field-helper-line-text {
    margin: 4px 0 0 0;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.md-text-field:hover .md-text-field-outline-container-left,
.md-text-field:hover .md-text-field-outline-container-middle,
.md-text-field:hover .md-text-field-outline-container-right {
  border-color: rgba(0, 0, 0, 0.87);
}

.md-text-field-container.--focused .md-text-field-outline-container-left,
.md-text-field-container.--focused .md-text-field-outline-container-middle,
.md-text-field-container.--focused .md-text-field-outline-container-right {
  border-width: 2px;
}

.md-text-field-container.--focused:not(.--invalid) .md-text-field-outline-container-left,
.md-text-field-container.--focused:not(.--invalid) .md-text-field-outline-container-middle,
.md-text-field-container.--focused:not(.--invalid) .md-text-field-outline-container-right {
  border-color: rgba(0, 0, 0, 0.87);
}

.md-text-field-container.--invalid .md-text-field-outline-container-left,
.md-text-field-container.--invalid .md-text-field-outline-container-middle,
.md-text-field-container.--invalid .md-text-field-outline-container-right {
  border-color: #b00020;
}

.md-text-field-container.--focused .md-text-field-outline-container-middle,
.md-text-field-container.--filled .md-text-field-outline-container-middle {
  border-top: none;
}

.md-text-field-container.--focused .md-text-field-label,
.md-text-field-container.--filled .md-text-field-label {
  transform: translateY(-17px) scale(0.85) !important;
}

.md-text-field-container.--focused:not(.--invalid) .md-text-field-label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.md-text-field-container.--invalid .md-text-field-label {
  color: #b00020 !important;
}

.md-text-field-container.--invalid .md-text-field-helper-line-text {
  color: #b00020 !important;
}