$box-shadow-size:3px 3px 8px;

.color-card-body {
  height: 150px;
  width: 300px;
  border-radius: 10px;
  border: 1px solid lighten(lightgray, 8%);
  position: relative;
  text-overflow: ellipsis;
  background-color: #FFFFFF;
}

.shape-circle-1 {
  clip-path: circle(35px at 50px 15px);
  float: left;
  top: 25px;
  left: 25px;
  width: 100px;
  height: 100px;
  z-index: -1;
}
.shape-circle-2 {
  clip-path: circle(25px at 80px 15px);
  width: 200px;
  height: 100px;
  z-index: -1;
}
.color-card-icon {
  position: absolute;
   right: 5%;
  top:5%;
  color:black;
}

.color-card-header {
 position: absolute;
  color: black;
  width: 100%;
  left: 2%;
  top: 30%;
  overflow-wrap: break-word;
  font-size: medium;
  z-index: 1;
}
.color-card-text {
  position: absolute;
  color: bisque;
  left: 5%;
  top: 45%;
  height: 45%;
  padding: 10px;
  overflow-y: auto ;
  scroll-behavior: smooth;
  font-size: medium;
  z-index: 1;
}

//blue card
.color-card-body[id="blue"] {
  box-shadow: $box-shadow-size lighten( #7E9AFF,10);
}
.shape-circle-1[id="blue"] {
  background-color: lighten($color: #7E9AFF, $amount: 10);
}

.shape-circle-2[id="blue"] {
  background-color: lighten($color: #7E9AFF, $amount: 12);
}

.color-card-header[id="blue"] {
  color:black;
}
.color-card-text[id="blue"]{
  p{
    color:darken(#65627c,15);
  }
}


//lightBlueCard
.color-card-body[id="lightBlue"] {
  box-shadow: $box-shadow-size lighten( #85c9f5,10);
  color: white;
}
.shape-circle-1[id="lightBlue"] {
  background-color: lighten($color: #81c3f2, $amount: 5);
}

.shape-circle-2[id="lightBlue"] {
  background-color: lighten($color: #80c2f2, $amount: 10);
}

//lightGreenCard
.color-card-body[id="lightgreen"] {
  color: white;
  box-shadow: $box-shadow-size lighten(#6be2e4,10);
}
.shape-circle-1[id="lightgreen"] {
  background-color: lighten($color: #67dbe2, $amount: 10);
}

.shape-circle-2[id="lightgreen"] {
  background-color: lighten($color: #65dae2, $amount: 15);
}

//greenCard
.color-card-body[id="green"] {
  box-shadow:$box-shadow-size #65d6b6;
  color: white;
}
.shape-circle-1[id="green"] {
  background-color: lighten($color: #63d0b9, $amount: 10);
}

.shape-circle-2[id="green"] {
  background-color: lighten($color: #61cfb9, $amount: 15);
}
