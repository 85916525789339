.md-progress-bar {
  position: relative;
  overflow: hidden;
  border: 0 none;
  background: #e5e7eb;
  border-radius: 6px;
  height: 6px;
  width: 100%;

  .md-progress-bar-value {
    border: 0 none;
    margin: 0;
    background: #3e5cb8;
    transition: width 1s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left,right;
      -webkit-animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(.65,.815,.735,.395) infinite;
      animation: p-progressbar-indeterminate-anim 2.1s cubic-bezier(.65,.815,.735,.395) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left,right;
      -webkit-animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(.165,.84,.44,1) infinite;
      animation: p-progressbar-indeterminate-anim-short 2.1s cubic-bezier(.165,.84,.44,1) infinite;
      -webkit-animation-delay: 1.15s;
      animation-delay: 1.15s;
    }
  }

  @-webkit-keyframes p-progressbar-indeterminate-anim {
    0% {
      left: -35%;
      right: 100%
    }
    60% {
      left: 100%;
      right: -90%
    }
    to {
      left: 100%;
      right: -90%
    }
  }

  @keyframes p-progressbar-indeterminate-anim {
    0% {
      left: -35%;
      right: 100%
    }
    60% {
      left: 100%;
      right: -90%
    }
    to {
      left: 100%;
      right: -90%
    }
  }

}