@import "../../scss/extends";

.input-text-container {
  display: flex;
  flex-direction: row;
  //gap: 6px;
  height: 35px;
}

.input-text-component-label{
  align-self: center;
}

.input-text-custom-item{
  display: flex;
  flex-direction: row;
  @extend .common-input;
  padding: 0 14px ;
  background-color: #FFFFFF;

  border : 1px solid #dee2e6;
  border-radius: 5px;
  &:focus-within{
    @extend .common-input-focus
  }
  input {
    border:none;
    &::placeholder{
      font-style: italic;
    }
    padding: 10px 0;
    &:focus  {
      .dropdown-input-text-container-content{
        display: block!important;
      }
      display: block;

    }
  }
}