.md-email-area.emails-input {
  max-height: inherit;
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #c3c2cf;
  box-sizing: border-box;
  padding: 0.375rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  cursor: text;
  overflow: auto;
}

.md-email-area.emails-input .email-chip {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  background: rgba(62, 92, 184, 0.2);
  vertical-align: top;
  border-radius: 6.25rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  margin: 0.125rem;
  max-width: 100%;
  overflow: hidden;
}

.md-email-area.emails-input .email-chip .content {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.md-email-area.emails-input .email-chip .remove {
  text-decoration: none;
  color: inherit;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 1rem;
  font-size: 1rem;
  line-height: 1rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-left: 4px;
  border-radius: 50%;
}

.md-email-area.emails-input .email-chip .remove:hover {
  background-color: rgba(62, 92, 184, 0.2);
}

//.md-email-area.emails-input .email-chip.invalid {
//  background: #fff;
//  border-bottom: 1px dashed #d92929;
//  border-radius: 0;
//  padding-left: 0;
//  padding-right: 1rem;
//}

.md-email-area.emails-input input {
  border: 0;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  background: transparent;
}

.md-email-area.emails-input input:focus {
  outline: none;
}

.md-email-area.emails-input input::-webkit-input-placeholder {
  font-style: italic;
  color: #95989a;
  opacity: 1;
}

.md-email-area.emails-input input::-moz-placeholder {
  font-style: italic;
  color: #95989a;
  opacity: 1;
}

.md-email-area.emails-input input::-ms-input-placeholder {
  font-style: italic;
  color: #95989a;
  opacity: 1;
}

.md-email-area.emails-input input::placeholder {
  font-style: italic;
  color: #95989a;
  opacity: 1;
}
