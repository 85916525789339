.train-ticket-component {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 5px;
  border: 1px solid #efefef;
  padding: 10px 15px;
  box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);

  .train-ticket-component-header {
    display: flex;
    flex-direction: row;

    .depart-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: auto;

      .city-code {
        font-size: 16px;
        font-weight: 500;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }

    .train-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .air-company-name {
        text-wrap: nowrap;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }

    .arrive-info {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: auto;

      .city-code {
        font-size: 16px;
        font-weight: 500;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }

  .train-ticket-component-content {
    display: flex;
    flex-direction: row;
    margin-top: 2px;

    .depart-info {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: auto;
      gap: 2px;

      .city-name {
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        width: 100px;
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #9B9B9B;
      }

      .datetime {
        text-align: left;
        font-size: 11px;
        font-weight: 500;
        color: #4A4A4A;
        text-transform: capitalize;
      }
    }

    .train-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        span {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #475AEF;
        }
        margin-bottom: 15px;
      }

      .number {
        font-size: 10px;
        font-weight: 500;
        color: #4A4A4A;
        background-color: #EFEFEF;
        border: 1px solid #D8D8D8;
        padding: 2px 6px;
        border-radius: 10px;
      }
    }

    .arrive-info {
      display: flex;
      flex-direction: column;
      align-items: end;
      width: auto;
      gap: 2px;

      .city-name {
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        width: 100px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        color: #9B9B9B;
      }

      .datetime {
        text-align: right;
        font-size: 11px;
        font-weight: 500;
        color: #4A4A4A;
        text-transform: capitalize;
      }
    }
  }
}