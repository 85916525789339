@import "../../../scss/mixins";

.login-form {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;

  .login-form-header {
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .login-form-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .login-form-error-message {
      p {
        width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
        padding: 10px;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        border-radius: 4px;
        font-size: 12px;
        color: #721c24;
      }
    }
  }

  .forgot-password-link {
    margin-top: 20px;
    color: rgba(33, 37, 41, 0.75);
    font-size: 12px;
    text-decoration: underline;
  }

  .sign-up-link {
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    color: #777777;
    padding: 0;
    font-size: 12px;
    font-weight: 500;

    a {
      color: #777777;
      padding: 0;
      font-size: 12px;
      text-decoration: underline;
      font-weight: 300;
    }
  }
}


@media (max-width: 991px) {
  .login-form {
    margin-top: 100px;
  }
}