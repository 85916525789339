.md-button {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  overflow: hidden;
  color: #ffffff;
  border-width: 1px;
  padding: 5px;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  --background-color: transparent;
  --highlighted-background-color: transparent;

  background-color: var(--background-color);

  /*box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.18), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);*/

  *{
    pointer-events: none;
  }

  &:not(:disabled):hover {
    /*box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);*/
    background-color: var(--highlighted-background-color);
  }
}

.md-button:not(:disabled):hover {
  /*box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);*/
  background-color: var(--highlighted-background-color);
}

.md-button:not(:disabled):active {
  /*box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);*/
}

.md-button:focus {
  /*box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;*/
}

.md-button[disabled] {
  pointer-events: none;
  opacity: 0.25;
}

.md-button i {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  max-width: 100%;
  max-height: 100%;

  svg {
    position: relative;
    display: block;
    //max-width: max-content;
    max-height: max-content;
  }
}

.md-button .md-button-text {
  flex: 1 1 auto;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.md-button .md-button-ink {
  display: block;
  position: absolute;
  background-color: rgba(255,255,255,.5);
  border-radius: 100%;
  transform: scale(0);
}

.md-button .md-button-ink.--ink-active {
  animation: ripple .4s linear
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2.5)
  }
}
