.md-toggle-button {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  border-width: 1px;
  padding: 5px 20px;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1), border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  background: transparent;
  color: #000000;
  /*box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);*/

  *{
    pointer-events: none;
  }
}

.md-toggle-button:focus,
.md-toggle-button:active{
  border:1px solid black;
  background:none;
  outline:none;
  padding:0;
}

.md-toggle-button:not(:disabled):hover {
  /*box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);*/
}

.md-toggle-button:not(:disabled):active {
  /*box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);*/
}

.md-toggle-button:focus {
  /*box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;*/
}

.md-toggle-button[disabled] {
  pointer-events: none;
  opacity: 0.25;
}

.md-toggle-button i {
  margin-left: -5px;
  margin-right: 10px;
}

.md-toggle-button .md-toggle-button-content {
  flex: 1 1 auto;
}

.md-toggle-button .md-toggle-button-ink {
  display: block;
  position: absolute;
  background-color: rgba(255,255,255,.5);
  border-radius: 100%;
  transform: scale(0);
}

.md-toggle-button .md-toggle-button-ink.--ink-active {
  animation: ripple .4s linear
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2.5)
  }
}
