@import "../../../../scss/extends";
@import "../../../../scss/default_variables";

//.authorized-users-row-container {
//  @extend .common-flex-row-container;
//  min-height: 200px;
//  margin-bottom: 6px;
//  // background-color: orange;
//}

.authorized-users-container {
  @extend .common-flex-row-container;
  padding: 8px 20px;
  align-content: space-between;
  //background-color: greenyellow;
  flex-wrap: wrap;
  margin-bottom: $gap-default;
}

.authorized-users-arrow-icon{
  align-content: space-between;
  align-self: center;
  align-items: center;
  padding: 10px;
}

.authorized-users-list {
  border: 2px solid gray;
  border-radius: 10px;
  min-width: 400px;

  .title {
    background-color: lighten(lightgrey, 5);
    border-bottom: 2px solid black;
    align-content: center;
    border-radius: 10px 10px 0 0;

    label {
      padding: 8px 16px;
    }
  }

  dl {
    min-height: 90px;
    max-height: 250px;
    overflow-y: auto;
    padding: 8px 8px 16px;
    border-radius: 5px;

    dd {
      display: flex;
      gap: 16px;
      flex-direction: row;
      border-bottom: 1px solid darken(lightgrey, 13);
      font-size: 1rem;
      //border-radius: 5px;
      padding: 4px 16px;
      margin-top: 3px;

      &:hover {
        background-color: $primary-color;
        border-radius: 10px;
        color: white;
      }

      .unselect-button {
        margin-left: auto;
        display: inline;
        cursor: pointer;
      }
    }
  }
}
