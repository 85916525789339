.add-company-form {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;

  .add-company-form-title {

  }

  .add-company-form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 30px;

    .add-company-form-fields-group {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .add-company-form-input-field {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .add-company-form-input-field-title {
          display: flex;
          align-items: start;
          font-size: 12px;
          padding: 0 2px;
        }
      }
    }

  }

  input,
  textarea {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    resize: none;

    &::-webkit-input-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::-moz-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &:focus {
      color: #212529;
      background-color: #ffffff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }
  }

  .error-message {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    font-size: 12px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 4px;
  }
}