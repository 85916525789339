.trip-builder-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;

  .trip-builder-form-header {
    padding: 10px 20px;

    .trip-builder-form-company-check {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .trip-builder-form-company-check-error-message {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 10px 32px;
      font-size: 12px!important;
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      border-radius: 4px;
    }

    .selected-company-details {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .company-item {
        display: flex;
        flex-direction: row;
        outline: none;
        background: none;
        margin: auto;
        width: 100%;
        justify-self: start;
        align-self: start;
        border-radius: 5px;
        border: 1px solid #efefef;
        margin-top: 5px;
        padding: 6px 0;
        background-color: #FFFFFF;
        box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);


        &:last-child {
          margin-bottom: 5px;
        }

        .company-item-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 100%;
          margin-top: 5px;
        }

        .company-item-details {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;

          .company-item-details-row {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: start;

            &:first-child {
              align-items: end;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:last-child {
              align-items: start;
            }

            .company-item-name {
              font-size: 12px;
              color: #242A2A;
              font-weight: 500;
              font-size: 12px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              padding: 0 5px 0 0;
            }

            .company-item-inn {
              font-size: 11px;
              color: #8E8E90;
              font-weight: 500;
            }

          }
        }

        .company-item-tools {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }

  .trip-builder-form-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .trip-builder-form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(-300px + 100vh);
    overflow: auto;
    padding: 0 20px 10px 20px;


    .trip-builder-form-content-gap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      width: 100%;
      height: auto;
      padding: 0 40px;

      >:nth-child(1) {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
      }

      >:nth-child(2) {
        position: relative;
        flex: 1;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #c5c5c5, #FFFFFF);
          background-size: 8px 2px, 8px 100%;
        }
      }

      >:nth-child(3) {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .passengers-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 0 0 0 40px;

      .passengers-list-item {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }

      .passenger-item {
        display: flex;
        flex-direction: row;
        outline: none;
        background: none;
        margin: 5px 0 0 0;
        width: 100%;
        height: auto;
        justify-self: start;
        align-self: start;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #f2f2f2;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;
        background-color: #FFFFFF;
        box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
        padding: 5px;

        .passenger-item-icon {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: start;
        }

        .passenger-item-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;

          .passenger-item-details-row {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: start;

            &:first-child {
              align-items: end;
            }

            &:last-child {
              align-items: start;
            }

            .passenger-fio {
              font-size: 12px;
              color: #242A2A;
              font-weight: 500;
            }

            .passenger-dob {
              font-size: 11px;
              color: #8E8E90;
            }

          }


          .passport-item {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            &.--expired:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 105%;
              height: 1px;
              background-color: rgba(255, 0, 0, 0.7);
            }

            .passport-item-type {
              color: #afafaf;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              //text-transform: uppercase;
              font-size: 12px;
            }

            .passport-item-number {
              flex: 1;
              color: #000000;
              text-align: left;
              font-size: 11px;
            }

            .passport-item-expiry {
              display: flex;
              align-items: center;
              justify-content: end;
              color: #8E8E90;
              text-transform: uppercase;
              font-size: 11px;
              text-align: right;
            }
          }

        }

        .trip-item-status-message {
          margin: 10px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 1px 10px 0 10px;
          border-radius: 4px;
          min-height: 24px;
          //max-height: 52px;
          font-size: 12px;
          color: #b12f00;
          border: 1px solid #b12f00;
          z-index: 1000;
          pointer-events: none;
          background-color: rgba(255, 255, 255, 0.8);

          .trip-item-status-message-timer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            font-size: 12px;
            font-weight: 500;
          }

          &.warn-status {
            color: #cd7402;
            border: 1px solid #cd7402;
          }

          &.success-status{
            color: #00b10f;
            border: 1px solid #00b11c;
          }

          &.error-status {
            color: #b12f00;
            border: 1px solid #b12f00;
          }

        }
      }

      .passengers-list-item-tools {
        display: flex;
        align-items: center;
      }
    }

    .ticket-list-item {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 5px;
      align-items: start;
      padding: 0 0 0 40px;

      >* {
        position: relative;
      }

      >*:nth-child(1) {
        width: auto;
      }
      >*:nth-child(2) {
        flex: 1;
      }
      >*:nth-child(3) {
        width: auto;
      }

      .trip-item-status-message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1px 10px 0 10px;
        border-radius: 4px;
        min-height: 24px;
        //max-height: 52px;
        font-size: 16px;
        color: #b12f00;
        border: 1px solid #b12f00;
        z-index: 1000;
        pointer-events: none;
        background-color: rgba(255, 255, 255, 0.8);

        .trip-item-status-message-timer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          font-size: 12px;
          font-weight: 500;
        }

        &.warn-status {
          color: #cd7402;
          border: 1px solid #cd7402;
        }

        &.success-status{
          color: #00b10f;
          border: 1px solid #00b11c;
        }

        &.error-status {
          color: #b12f00;
          border: 1px solid #b12f00;
        }

      }

    }

    .order-summary-info {
      display: flex;
      flex-direction: column;
      padding: 10px 40px 5px 74px;

      .order-summary-info-item {
        display: flex;
        flex-direction: row;
        gap: 5px;

        span:nth-child(1) {
          font-size: 12px;
        }

        span:nth-child(2) {
          position: relative;
          flex: 1;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #c5c5c5, #FFFFFF);
            background-size: 8px 2px, 8px 100%;
          }
        }

        span:nth-child(3) {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }

    .booking-pending-message {
      flex: none;
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      margin: 10px auto;
      padding-top: 0;
    }

    .booking-error-message {
      width: 90%;
      text-align: center;
      margin: 10px auto;
      padding: 10px;
      font-size: 12px!important;
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      border-radius: 4px;
    }

    .booking-complete-message {
      width: 90%;
      text-align: center;
      margin: 10px auto;
      padding: 10px;
      font-size: 12px !important;
      color: #04850f;
      background-color: #cdffd5;
      border-color: #ffeeba;
      border-radius: 4px;
    }
  }

  .trip-builder-form-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
    padding: 10px 20px 20px 10px;
  }

}