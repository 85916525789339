@import "../../../../scss/extends.scss";
@import "../../../../scss/default_variables.scss";


.company-info-container {
  @extend .common-flex-column-container;
  gap: $gap-default;
}

.company-info-block {
  @extend .common-flex-column-container;
  padding: $padding-row-container-default;
  gap: $gap-default;

  div[typeof="company-label"] {

    font-size: 1rem;
    color: white;
  }

  div[typeof="company-info"] {
    font-size: 0.9rem;

  }

}


.company-info-row-container {
  @extend .common-flex-row-container;

  div[typeof="company-name-block"] {
    @extend .company-info-block;
    width: 300px;
  }

  div[typeof="agreement-no-block"] {
    @extend .company-info-block;
    width: 150px;
  }

  div[typeof="address-block"] {
    @extend .company-info-block;
    width: 380px;
  }

  div[typeof="last-elements"] {
    margin-left: auto;
    display: flex;
    flex-direction: row;

    div[typeof="action-block"] {
      @extend .company-info-block;
      width: 150px;
      align-self: center;
      p {
        color: white;
        width: 70px;
      }
    }
    div[typeof = "access-denied-msg"]{
      position: absolute;
      display: inline;
      width: 170px;
      margin-top: 35px;
      background-color: white;
      border:1px solid gray;
      border-radius: 5px;
      color:red;
      padding: 4px 8px;
      transform: translateX(-25%) translateY(-200%) scale(110%);
      z-index: 6;
    }

  }

}

//.company-info-row-container-header{
//  @extend .company-info-row-container;
//  background-color: $primary-color;
//}

.company-info-row-container:nth-child(even) {
  background-color: lighten(lightsteelblue, 17);
}

.company-info-row-container-header {
  @extend .company-info-row-container;
  background-color: $primary-color;
}

.vertical-border-company-info {
  color: gray;
  border-left: 1px solid;
  padding: 5px 2px;
  //border-image: linear-gradient(to bottom, lightgray, $primary-color) 0 1;
}
//.company-info-popover-message{
//  position: absolute;
//  visibility: hidden;
//  margin-top: 35px;
//  background-color: whitesmoke;
//  border:1px solid gray;
//  border-radius: 5px;
//  color:red;
//  padding: 4px 8px;
//  transform: translateX(-25%) translateY(-200%) scale(110%);
//  z-index: 6;
//}


