.md-select {
  position: relative;
  margin: 5px 0;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #e2eded;
  border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;

  &.--disabled * {
    pointer-events: none;
    opacity: 0.75;
  }

  .options-view-button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
    border: none;
    outline: none;
    box-shadow: none;
  }


  .md-select-text-field {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: auto;
    padding: 6px  6px 6px 14px;
    background-color: transparent;
    font-size: inherit;
    border: none;
    outline: none;
    box-shadow: none;
    color: transparent;

    &.--focused {
      color: #000000;
    }
  }

  &.--opened .md-select-text-field{
    color: #000000;
  }

  .toggle-button {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    height: auto;
    padding: 6px  6px 6px 14px;
    background-color: #fff;
    cursor: pointer;
    font-size: inherit;

    .selected-value {
      flex: 1;
      overflow: hidden;
    }

    .toggle-button-icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .options {
    position: absolute;
    display: none;
    flex-direction: column;
    left: 0;
    top: calc(100% + 3px);
    right: 0;
    width: auto;
    width: max-content;
    min-width: 100%;
    max-height: 290px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #e2eded;
    border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    z-index: 1000;

    &:not(.--empty).--opened {
      display: flex;
    }

    .option {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 10px;
      cursor: pointer;
      transition: 0.3s ease all;
      z-index: 2;

      &:hover .label:before {
        //background-color: #F2F2F2;
        background-image: linear-gradient(120deg, rgb(245, 245, 245) 0%, rgb(245, 245, 245) 100%);
        border-radius: 4px 4px 0 0;
      }

      &.--selected .label:before {
        //background-color: #E8E8E8;
        background-image: linear-gradient(120deg, rgb(230, 230, 230) 0%, rgb(240, 240, 240) 100%);
        border-radius: 4px 4px 0 0;
      }

      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
      }

      input:focus + .label:before {
        //background-color: #E8E8E8;
        background-image: linear-gradient(120deg, rgb(230, 230, 230) 0%, rgb(240, 240, 240) 100%);
        border-radius: 4px 4px 0 0;
      }

      .label {
        width: 100%;
        color: #000000;
        font-size: inherit;
        padding: 6px 14px;
        transition: 0.3s ease all;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
        }
      }

    }

  }

}
































//.md-select-container {
//  position: relative;
//  display: flex;
//  flex-direction: column;
//
//  .md-select,
//  .md-select--outlined {
//    position: relative;
//    display: flex;
//    align-items: center;
//    padding-left: 16px;
//    padding-right: 16px;
//    min-height: 36px;
//    cursor: pointer;
//  }
//
//  .md-select:before,
//  .md-select--outlined:before{
//    content: "";
//    position: absolute;
//    display: block;
//    top: 50%;
//    transform: translate(0, -6px);
//    right: 10px;
//    border-left: 0.3rem solid transparent;
//    border-right: 0.3rem solid transparent;
//    border-bottom: 0.3rem solid black;
//    pointer-events: none;
//  }
//
//  .md-select:after,
//  .md-select--outlined:after{
//    content: "";
//    position: absolute;
//    display: block;
//    top: 50%;
//    transform: translate(0, 2px);
//    right: 10px;
//    border-left: 0.3rem solid transparent;
//    border-right: 0.3rem solid transparent;
//    border-top: 0.3rem solid black;
//    pointer-events: none;
//  }
//
//  .md-select-field-input {
//    display: flex;
//    width: 100%;
//    height: 100%;
//    border: none;
//    outline: none;
//    background-color: rgba(0, 0, 0, 0);
//    caret-color: rgba(0, 0, 0, 1.0);
//    color: rgba(0, 0, 0, 0.87);
//    font-size: 1rem;
//    font-weight: 300;
//    pointer-events: none!important;
//    text-overflow: ellipsis;
//  }
//
//  .md-select-outline-container {
//    display: flex;
//    position: absolute;
//    top: 0;
//    right: 0;
//    left: 0;
//    box-sizing: border-box;
//    width: 100%;
//    max-width: 100%;
//    height: 100%;
//    text-align: left;
//    pointer-events: none;
//
//    .md-select-outline-container-left {
//      border-top-left-radius: 4px;
//      border-top-right-radius: 0;
//      border-bottom-right-radius: 0;
//      border-bottom-left-radius: 4px;
//
//      width: 12px;
//      border-left: 1px solid;
//      border-right: none;
//      border-top: 1px solid;
//      border-bottom: 1px solid;
//      border-color: rgba(0, 0, 0, 0.38);
//    }
//
//    .md-select-outline-container-middle {
//      display: flex;
//      align-items: center;
//      border-top: 1px solid;
//      border-bottom: 1px solid;
//      border-color: rgba(0, 0, 0, 0.38);
//      flex: 0 0 auto;
//      width: auto;
//
//      .md-select-label {
//        pointer-events: none;
//        display: inline-block;
//        position: relative;
//        max-width: 100%;
//        color: rgba(0, 0, 0, 0.38);
//        font-size: 13px;
//        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
//      }
//    }
//
//    .md-select-outline-container-right {
//      border-top-left-radius: 0;
//      border-top-right-radius: 4px;
//      border-bottom-right-radius: 4px;
//      border-bottom-left-radius: 0;
//      flex-grow: 1;
//      border-left: none;
//      border-right: 1px solid;
//      border-top: 1px solid;
//      border-bottom: 1px solid;
//      border-color: rgba(0, 0, 0, 0.38);
//    }
//  }
//
//  .md-select-helper-line {
//    padding-right: 16px;
//    padding-left: 16px;
//  }
//
//  .md-select-helper-line-text {
//    margin: 4px 0 0 0;
//    font-size: 11px;
//    color: rgba(0, 0, 0, 0.6);
//  }
//}
//
//ul.md-select-menu {
//  position: absolute;
//  display: flex;
//  flex-direction: column;
//  left: 0;
//  top: calc(100% + 1px);
//  width: 100%;
//  max-height: 105px;
//  overflow: auto;
//  border-radius: 4px;
//  border: 1px solid rgba(0, 0, 0, 0.1);
//  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
//  background: #f5f5f5;
//}
//
//ul.md-select-menu li {
//  padding: 5px 10px !important;
//  cursor: pointer;
//  overflow: hidden;
//  text-wrap: nowrap;
//  text-overflow: ellipsis;
//}
//
//ul.md-select-menu li:hover {
//  padding: 0 10px;
//  background: #ededed;
//}
//
//.md-select:hover .md-select-outline-container-left,
//.md-select:hover .md-select-outline-container-middle,
//.md-select:hover .md-select-outline-container-right {
//  border-color: rgba(0, 0, 0, 0.87);
//}
//
//.md-select-container.--focused .md-select-outline-container-left,
//.md-select-container.--focused .md-select-outline-container-middle,
//.md-select-container.--focused .md-select-outline-container-right {
//  border-width: 2px;
//}
//
//.md-select-container.--focused:not(.--invalid) .md-select-outline-container-left,
//.md-select-container.--focused:not(.--invalid) .md-select-outline-container-middle,
//.md-select-container.--focused:not(.--invalid) .md-select-outline-container-right {
//  border-color: rgba(0, 0, 0, 0.87);
//}
//
//.md-select-container.--invalid .md-select-outline-container-left,
//.md-select-container.--invalid .md-select-outline-container-middle,
//.md-select-container.--invalid .md-select-outline-container-right {
//  border-color: #b00020;
//}
//
//.md-select-container.--focused .md-select-outline-container-middle,
//.md-select-container.--filled .md-select-outline-container-middle {
//  border-top: none;
//}
//
//.md-select-container.--focused .md-select-label,
//.md-select-container.--filled .md-select-label {
//  transform: translateY(-17px) scale(0.85) !important;
//}
//
//.md-select-container.--focused:not(.--invalid) .md-select-label {
//  color: rgba(0, 0, 0, 0.87) !important;
//}
//
//.md-select-container.--invalid .md-select-label {
//  color: #b00020 !important;
//}
//
//.md-select-container.--invalid .md-select-helper-line-text {
//  color: #b00020 !important;
//}