@mixin laptop() {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin tablet() {
  @media (max-width: 770px) {
    @content;
  }
}

@mixin iphoneSe() {
  @media (max-width: 475px) {
    @content;
  }
}

// @mixin iphoneSe() {
//   @media (min-width: 320px) {
//     @content;
//   }
// }




/*
  Device = Desktops
  Screen = 1281px to higher resolution desktops
*/
@mixin desktop() {
  @media (min-width: 1281px) {
    @content;
  }
}

/*
  Device = Laptops, Desktops
  Screen = from 1025px to 1280px
*/
@mixin laptop() {
  @media (min-width: 1025px) and (max-width: 1280px) {
    @content;
  }
}

/*
  Device = Tablets, Ipads (portrait)
  Screen = from 768px to 1024px
*/
@mixin tabletPortrait() {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

/*
  Device = Tablets, Ipads (landscape)
  Screen = from 768px to 1024px
*/
@mixin tabletLandscape() {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

/*
  Device = Low Resolution Tablets, Mobiles (Landscape)
  Screen = from 481px to 767px
*/
@mixin mobilesLandscape() {
  @media (min-width: 481px) and (max-width: 767px) {
    @content;
  }
}

/*
  Device = Most of the Smartphones Mobiles (Portrait)
  Screen = from 320px to 479px
*/
@mixin mobilesPortrait() {
  @media (min-width: 320px) and (max-width: 480px) {
    @content;
  }
}