.users-results {
    display: flex!important;
    flex-direction: column!important;
    padding: 24px 22px 24px 22px !important;
    gap: 6px!important;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;

    .users-results-header {
        display: flex!important;
        flex-direction: row!important;
    }
    .users-results-caption {
        display: flex;
        align-items: center;
        font-size: 18px!important;
        font-weight: 500!important;
        color: #161A1F!important;
    }

    .users-results-caption span {
        padding-left: 5px;
        font-size: 18px!important;
        font-weight: 300!important;
        color: #A9ADB1!important;
    }

    .users-results-tools {
        flex: 1;
        display: flex!important;
        flex-direction: row!important;
        align-items: center;
        justify-content: end;
        gap: 10px;
    }

    .users-results-preloader {
        margin-left: 5px !important;
        display: flex;
        align-items: center;

        .users-results-preloader-icon {
            width: 16px !important;
            height: 16px !important;
            border-width: 2px !important;
        }
        .users-results-preloader-text {
            font-size: 12px !important;
        }
    }

    .empty-search-result-message {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        padding: 10px;
        font-size: 12px!important;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        border-radius: 4px;
    }
}