.send-documents-form {
  display: flex;
  flex-direction: column;
  padding: 0 40px 20px 40px;
}

.send-documents-form-sections {
  max-height: 400px;
  overflow: visible;

  .send-documents-form-section-title {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 0.5em 0;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    font-style: oblique;
  }
  .send-documents-form-section-content {
    opacity: 1;
    transition: all 200ms linear;
    will-change: opacity, max-height;
    padding: 0 10px 0 0;
  }
}

.send-documents-form-format-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.error-message {
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 20px;
  padding: 10px;
  font-size: 12px!important;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 4px;
}