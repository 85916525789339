//* Colors
$primary-color-old: #1a2b63 !global;
$second-color: #122b45 !global;
$third-color: #d0e6ff !global;
$fourth-color: #1fceff !global;
$background-primary-color: #aaaabc !global;

//cabinet colors
$primary-color: #3E5CB8 !global;
$secondary-color: #3a3f52 !global;
$error-color: #b00020 !global;
$surface-color: #FFFFFF !global;
$primary-background: #FFFFFF !global;
$secondary-background: #F8F9FB !global;
$on-primary-color: #FFFFFF !global;
$on-secondary-color: whitesmoke !global;
$on-primary-background-color: #3a3f52 !global;
$on-primary-background-variant-color: #000000 !global;
$on-secondary-background-color: #3a3f52 !global;
$on-secondary-background-variant-color: #000000 !global;
$on-surface-color: #3a3f52 !global;
/*$primary-color: #424856 !global;*/
$accentGreenBackgroundColor: #81e98c !global;
$background-color-primary-light-green: #eeffe3 !global;

//border-colors
$primary-disable-border-color:#C1C1C1;
$primary-active-border-color:$primary-color;
$primary-hover-border-color:#86b7fe;


//input elements
$border-input-default: 1px solid #dee2e6;
$height-input-field-desktop:35px;
//$input-padding:

//Dimensions
$padding-row-container-default: 8px 16px;
$gap-default: 6px;

//other color

//design by figma
//? color with design template
// $figma-bg-color: #eeffe3;
// $figma-bg-second-color: #d4ffb9;
// $ticket-definition-figma: #dbffff;

//test
$figma-bg-color: #eeffe3;
$figma-bg-second-color: #d4ffb9;
$ticket-definition-figma: #dbffff;

//*Alternative color with app
//$figma-bg-color: #dae8e7;

//dark
$cabinet-header-color: #424856;
$cabinet-header-primary-text-color: #c9ccd3;
//light
//$cabinet-header-color: #b6bfb0;
//$cabinet-header-primary-text-color: #71776d;
//$cabinet-header-primary-text-color: black;
$cabinet-header-secondary-text-color: #5183ff;

//$main-background-primary-color:

$check-Color: green !global;

//* screen
$breacpoints_1440: 1440 px;
$breacpoints_1200: 1200 px;
$breacpoints_1000: 1000 px;
$breacpoints_IpadMini: 768 px;
$breacpoints_IphoneSE: 375 px;
