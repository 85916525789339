.md-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0,0,0,.32);
  backdrop-filter: blur(2px);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  transition-duration: 250ms;
  transition-property: opacity, visibility;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  cursor: default;
  /*transform: scale(0);*/
  z-index: 10000;
}

.md-modal:not([data-opened="true"]) * {
  pointer-events: none!important;
}

.md-modal[data-opened="true"] {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  cursor: default;
  /*transform: scale(1);*/
}

.md-modal--window {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  min-width: 240px;
  min-height: 180px;
  background-color: #ffffff;
  border-radius: 4px;
  margin: auto;
  transition: transform 0.5s, opacity 0.25s;
}

.md-modal[data-opened="true"] .md-modal--window {
  animation: 0.3s ease-in 0s 1 md-modal-window-animation;
}

@keyframes md-modal-window-animation {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0);
  }
}

.md-modal--header {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 52px;
  margin: 0;
  padding: 8px;
}

.md-modal--header:after{
  content: "";
  position: absolute;
  display: block;
  bottom: 5px;
  left: 20px;
  right: 20px;
  height: 1px;
  background-color: #DDDDDD;
}

.md-modal--header-title {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 10px;
}

.md-modal--header-buttons {
  display: flex;
  flex-direction: row;
  //align-items: center;
}

.md-modal--content {
  flex: 1;
  padding-top: 10px;
  overflow: auto;
}

.md-modal--footer {
  min-height: 82px;
  margin: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 40px;
}
