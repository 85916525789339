.no-orders-message {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  .no-orders-message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-image: -moz-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
    background-image: -webkit-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
    background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
  }

  .no-orders-message-caption {
    font-size: 14px;
    font-weight: 500;
    color: #27292A;
  }

  .no-orders-message-content {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: #3C4145;
    max-width: 200px;
    text-align: center;
  }

  .no-orders-message-button {
    margin-top: 40px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}