@import "./default_variables.scss";
.main-order-table {
  border-collapse: collapse;
  background-color: white;
  border: 1px solid black;
  width: 100%;
  overflow-x: "scroll";

  thead {
    border: 1px solid black;

    tr {
      font-size: 12px;
      &:hover {
        background-color: aqua;
        th {
          &:hover {
            background-color: darken($color: aqua, $amount: 15);
          }
        }
      }
    }
  }
  tbody {
    background-color: white;
    border: 1px solid black;
    tr {
      font-family: monospace;
      font-size: 14px;
      th {
        height: 30px;
        border: 1px dotted rgb(224, 224, 226);
      }
      td {
        border: 1px dashed rgb(31, 31, 148);
      }

      &:hover {
        background-color: aqua;
        td {
          &:hover {
            background-color: darken($color: aqua, $amount: 15);
          }
        }
      }
    }
  }
}

.additional-order-table {
  border: 1px solid black;
  //background-color: green;
  font-size: 12px;

  background: green;
  tr {
    th {
      border: 50px dotted black;

      font-size: 12px;
      &:hover {
        background-color: aqua;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 1px dashed rgb(31, 31, 148);

        font-size: 12px;
        &:hover {
          background-color: aqua;
        }
      }
    }
  }
}

.additional-table-bordered-cell {
  border: 1px solid #6237b2;
}

.common-document-stripped-table {
  position: relative;
  overflow: hidden;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;

  th,
  tr,
  td {
    border: none;
    position: relative;
  }

  tr {
    cursor: pointer;
    //box-shadow: 1px 1px 5px 1px #f5f5f5;
  }

  thead tr {
    background-image: linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%);
  }

  tr th {
    border: 1px solid #f5f5f5;
    background-color: transparent;
    text-align: center;
    color: #95989a;
    font-size: 11px;
    font-weight: 400;
    border-left-width: 0;
    border-right-width: 0;
    //text-transform: uppercase;
    //padding: 12px 5px;
    padding: 10px 5px 5px 2px;
    vertical-align: middle;
  }
  tr th:first-child {
    border-left-width: 1px;
  }
  tr th:last-child {
    border-right-width: 1px;
  }

  tr td {
    border: 1px solid #f5f5f5;
    border-left-width: 0;
    border-right-width: 0;
    color: #161a1f;
    font-size: 12px;
    padding: 10px 10px !important;
    text-align: center;
  }

  tr td:first-child {
    border-left-width: 1px;
  }
  tr td:last-child {
    border-right-width: 1px;
    padding: 0!important;
    vertical-align: middle;
  }

  --bs-table-hover-color: #000000 !important;
  --bs-table-hover-bg: #fcfcfc !important;

  tr.selected-row {
    //background-color: #fcc92f;
    background-color: rgba(62, 122, 204, 0.8);
    td {
      color: #FFFFFF;
      background-color: transparent !important;
      border-bottom: 1px solid #d3d3d3;
    }
    .row-details-toggle-button {
      filter: invert(1);
    }
  }

  tr:not(.selected-row):hover td {
    border-bottom: 1px solid #ececec;
  }

  tr:not(.selected-row:hover) {
    --bs-table-hover-bg: #fcfcfc !important;
  }

  tr.selected-row:hover {
    //--bs-table-hover-bg: #fcc92f !important;
    --bs-table-hover-bg: rgba(62, 122, 204, 0.8) !important;
  }

  /*
  tr:nth-of-type(odd) {
    background-color: $figma-bg-color;
    th {
      background-color: darken($figma-bg-color, 15);
    }
    td {
      background-color: $figma-bg-color;
    }
  }

  tr:nth-of-type(even) {
    background-color: white;
    th {
      background-color: darken(white, 15);
    }
    td {
      background-color: white;
    }
  }
  */
}

.table-headers:after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ededed;
}

.scroll-table {
  width: 100%;
  border: none;
  background-color: #f6f6f7;
  //background-image: linear-gradient(120deg, rgb(246, 246, 247) 0%, rgb(250, 250, 250) 100%);
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.05);
}

.scroll-table table {
  width: 100%;
  table-layout: fixed;
  border: none;
}
.scroll-table-body {
  height: 100%;
  max-height: 50vh;
  overflow-x: auto;
  margin-top: 0px;
}

/*
.scroll-table thead th {
  font-weight: bold;
  text-align: left;
  border: none;
  padding: 10px 15px;
  background: #d8d8d8;
  font-size: 14px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.scroll-table tbody td {
  text-align: left;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 14px;
  vertical-align: top;
}
.scroll-table tbody tr:nth-child(even){
  background: #f3f3f3;
}
*/



.row-details-toggle-button {
  width: 20px !important;
  height: 20px !important;
  border: none;
  outline: none;
  background-color: transparent;
}
.row-details-toggle-button {
  font-size: 10px;
  width: 1em;
  height: 1em;
  position: relative;
  display: inline-block;
}

.row-details-toggle-button::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 50%;
  margin-left: -6px;
  width: 12px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 12px;
  transition: 0.3s ease-in-out all;
  background-position: center;
  transform: rotate(90deg);
}

.row-details-toggle-button.--expanded::after {
  transform: rotate(0deg);
}

.document-pdf-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  //background: repeating-linear-gradient(45deg, rgba(62, 92, 184, 0.08), rgba(62, 92, 184, 0.08) 10px, rgb(255, 255, 255) 10px, rgb(255, 255, 255) 20px);
  background-color: rgba(62, 92, 184, 0.05);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
}

.pagination-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}



.table-skeleton-preloader {
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-skeleton-item {
  width: 100%;
  height: 39px;
  background: #FAFAFA;
  background: linear-gradient(110deg, #FAFAFA 8%, #f5f5f5 18%, #FAFAFA 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/*
.table-spinner-preloader {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
*/