.trip-page-search-form-header {

  .train-type {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    color: #000000;
    //min-width: 100px;
  }
  .train-type-variants {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
  }

  .train-passengers {
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    color: #000000;
    //min-width: 100px;
  }
  .train-passengers-control {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px;

    input[type="number"] {
      flex: 1;
      height: 20px;
      border: none;
      background: none;
      outline: none;
      box-shadow: none;
      text-align: center;
      font-size: 14px;
      -moz-appearance: textfield;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .passengers-input-control {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 10px;

    input[type="number"] {
      flex: 1;
      height: 20px;
      border: none;
      background: none;
      outline: none;
      box-shadow: none;
      text-align: center;
      font-size: 14px;
      -moz-appearance: textfield;

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

}