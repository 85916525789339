@import "../../../scss/default_variables";

//Old
//.loading{
//  display: inline-block;
//}
//.loading-text {
//  width: 100%;
//  //height: 100px;
//  //line-height: 100px;
//  span {
//    display: inline-block;
//    margin: 0 5px;
//    color: $primary-color;
//    font-size: 1.2rem;
//    font-weight: 600;
//    @for $i from 0 through 20 {
//      &:nth-child(#{$i + 1}) {
//        filter: blur(0px);
//        animation: blur-text 1s (#{$i/8})+s infinite linear alternate;
//      }
//    }
//  }
//}
//
//@keyframes blur-text {
//  0% {filter: blur(0px);}
//  100% {filter: blur(4px);}
//}


//body {
//  background: #222;
//  text-align: center;
//  padding: 20%;
//}



/*
 * Loading Dots
 * Can we use pseudo elements here instead :after?
 */
.loading-container{
  display: flex;
  flex-direction: column;
  //flex-basis: 0;
  //width: 220px;

  .loading-header{
    p{
      text-align: center;
      color: #007DB6;
      font: .8em verdana;
      text-transform: uppercase;
      letter-spacing: .1em;
    }
    //text-align: center;
    //color: #007DB6;
    //font: .8em verdana;
    //text-transform: uppercase;
    //letter-spacing: .1em;
  }
}

.loading-dots {
  span {
    display: inline-block;
    width: .6em;
    height: .6em;
    margin: .19em;
    background: #007DB6;
    border-radius: .6em;
    animation: loading 1s infinite alternate;
  }

  span:nth-of-type(2) {
    background: #008FB2;
    animation-delay: 0.2s;
  }

  span:nth-of-type(3) {
    background: #009B9E;
    animation-delay: 0.4s;
  }

  span:nth-of-type(4) {
    background: #00A77D;
    animation-delay: 0.6s;
  }

  span:nth-of-type(5) {
    background: #00B247;
    animation-delay: 0.8s;
  }
  span:nth-of-type(6) {
    background: #5AB027;
    animation-delay: 1s;
  }
  span:nth-of-type(7) {
    background: #A0B61E;
    animation-delay: 1.2s;
  }
  span:nth-of-type(8) {
    background: #5AB027;
    animation-delay: 1.4s;
  }
  span:nth-of-type(9) {
    background: #00B247;
    animation-delay: 1.6s;
  }
  span:nth-of-type(10) {
    background: #00A77D;
    animation-delay: 1.8s;
  }
  span:nth-of-type(11) {
    background: #009B9E;
    animation-delay: 2.0s;
  }
  span:nth-of-type(12) {
    background: #008FB2;
    animation-delay: 2.2s;
  }
  span:nth-of-type(13) {
    background: #008FB2;
    animation-delay: 2.4s;
  }
}


.loading span {
  display: inline-block;
  vertical-align: middle;
  width: .6em;
  height: .6em;
  margin: .19em;
  background: #007DB6;
  border-radius: .6em;
  animation: loading 1s infinite alternate;
}

/*
 * Dots Colors
 * Smarter targeting vs nth-of-type?
 */
.loading span:nth-of-type(2) {
  background: #008FB2;
  animation-delay: 0.2s;
}

.loading span:nth-of-type(3) {
  background: #009B9E;
  animation-delay: 0.4s;
}

.loading span:nth-of-type(4) {
  background: #00A77D;
  animation-delay: 0.6s;
}

.loading span:nth-of-type(5) {
  background: #00B247;
  animation-delay: 0.8s;
}

.loading span:nth-of-type(6) {
  background: #5AB027;
  animation-delay: 1.0s;
}

.loading span:nth-of-type(7) {
  background: #A0B61E;
  animation-delay: 1.2s;
}

/*
 * Animation keyframes
 * Use transition opacity instead of keyframes?
 */
@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}