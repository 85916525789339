@import "../../../../scss/default_variables";

$box-shadow-size:5px 5px 8px;

.statistic-simple-card {
  border-radius: 10px;
  border: 2px solid lightgrey;
  min-width: 180px;

  .title {
    background-color: lighten(lightgrey, 12);
    border-bottom: 2px solid black;align-content: center;
    border-radius: 10px 10px 0 0;
    padding: 8px 16px;
    label {
      padding: 8px 16px;
    }
  }
}

.statistic-simple-card[id="blue"]{
  box-shadow: $box-shadow-size lighten( #7E9AFF,5);
}

.statistic-simple-card[id="lightBlue"]{
  box-shadow: $box-shadow-size lighten( #85c9f5,10);
}

.statistic-simple-card[id="lightGreen"]{
  box-shadow: $box-shadow-size lighten(#6be2e4,10);
}

.statistic-simple-card[id="green"]{
  box-shadow:$box-shadow-size #65d6b6;
}