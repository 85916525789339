@import "default_variables";

.filters-container {
  display: flex;
  flex-direction: column;
  padding: 24px 22px 12px 22px;
  gap: 6px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);


  .filters-caption {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #161A1F !important;
  }

  .filters-description {
    font-size: 12px !important;
    font-weight: 300 !important;
    color: #A9ADB1 !important;
  }

  .filters-form {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 0 !important;
    gap: 6px;

    .filters-form-fields-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .filters-form-fields-group {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }

      .filters-form-input-field {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        label {
          align-self: center;
        }

        input {
          display: block;
          padding: .375rem .75rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #212529;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #ffffff;
          background-clip: padding-box;
          border: 1px solid #dee2e6;
          border-radius: 0.375rem;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

          &::-webkit-input-placeholder {
            font-style: italic;
            color: #95989a;
            opacity: 1;
          }

          &::-moz-placeholder {
            font-style: italic;
            color: #95989a;
            opacity: 1;
          }

          &::-ms-input-placeholder {
            font-style: italic;
            color: #95989a;
            opacity: 1;
          }

          &::placeholder {
            font-style: italic;
            color: #95989a;
            opacity: 1;
          }

          &:focus {
            color: #212529;
            background-color: #ffffff;
            border-color: #86b7fe;
            outline: 0;
            box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
          }
        }
      }
    }
  }

  input {
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
  }

  button {
    outline: none !important;
    box-shadow: none !important;
    font-size: 14px !important;
  }
}

.common-flex-column-container {
  display: flex !important;
  flex-direction: column !important;
}

.common-flex-row-container {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
}

.common-vertical-border {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 4px;
}

.common-input {
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px !important;
}

.common-input-focus {
  color: #212529;
  background-color: #ffffff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}

.common-clear-input {
  align-self: center;
  cursor: pointer;
  width: 14px;
}


.common-button-md-item {
  //outline: none !important;
  //box-shadow: none !important;
  //font-size: 14px!important;
  // color:red !important;
}

.common-label {
  label {
    pointer-events: none;
    position: absolute;
    transition: 0.2s;
    transform: scale(0.75) translateY(-85%) translateX(15px);
    opacity: 1;
    font-size: 1.1rem;
    font-style: italic;
    border-radius: 5px;
    background-color: $primary-color;
    color: white;
    padding: 2px 8px;
  }
}

//order page
.common-order-info-container {
  @extend .common-flex-column-container;
  margin: 2px 16px;
  border-radius: 0 0 15px 15px;
  //box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.3);
  padding: 0 16px;
  //background-color: #f9fff1;
  gap: 3px;

  p {
    margin-bottom: 0;
    margin-top: 5px;
    color: black;
    font-size: 1rem;
    font-weight: 600;
  }
}

.common-order-info-item {
  display: flex;
  flex-direction: row;
  border: 1px solid gray;
  border-radius: 10px;
  align-content: space-between;
  gap: 3px;
  padding: 10px 10px;
  font-size: 0.8rem;
  background-color: white;

  div:last-child {
    margin-left: auto;
  }
}


.common-custom-list {
  border: 2px solid gray;
  border-radius: 10px;

  .title {
    background-color: lighten(lightgrey, 5);
    border-bottom: 2px solid black;
    align-content: center;
    border-radius: 10px 10px 0 0;

    label {
      padding: 8px 16px;
    }
  }

  dl {
    padding: 8px 8px 16px;
    border-radius: 5px;

    dd {
      display: flex;
      gap: 16px;
      flex-direction: row;
      border-bottom: 1px solid darken(lightgrey, 13);
      font-size: 1rem;
      padding: 4px 16px;
      margin-top: 3px;


      .unselect-button {
        align-self: center;
        margin-left: auto;
        display: inline;
        cursor: pointer;
      }
    }
  }
}


