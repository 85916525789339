
.md-table {
  position: relative;
  overflow: hidden;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 10px;
  flex-direction: column;
  --row-border-color: #f5f5f5;
  --row-highlighted-background: #fcfcfc;
  --row-highlighted-border-color: #ececec;
  --selected-row-background: "rgba(62, 92, 184, 0.8)";
  --selected-row-highlighted-background: "rgba(62, 92, 184, 0.9)";
  --selected-row-highlighted-border-color: #d3d3d3;
  --selected-row-font-color: #FFFFFF;

  th,
  tr,
  td {
    border: none;
    position: relative;
  }

  tr {
    cursor: pointer;
    //box-shadow: 1px 1px 5px 1px #f5f5f5;
  }

  thead {
    display: flex;
    flex-direction: row;

    tr {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex: 1;
      background-image: linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%);
      border: 1px solid #f5f5f5;

      th {
        flex: 1;
        background-color: transparent;
        text-align: center;
        color: #95989a;
        font-size: 11px;
        font-weight: 400;
        border-left-width: 0;
        border-right-width: 0;
        padding: 10px 5px 5px 2px;
        vertical-align: middle;
        min-height: 36px;
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    overflow: auto;

    tr {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      flex: 1;

      td {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border: 1px solid var(--row-border-color);
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
        color: #161a1f;
        font-size: 12px;
        padding: 8px;
        text-align: center;

        &:first-child {
          border-left-width: 1px;
        }

        &:last-child {
          border-right-width: 1px;
        }

        &.tools-cell {
          padding: 0;
          width: auto!important;
          min-width: auto!important;
          background-color: rgba(200, 200, 200, 0.1);
          -webkit-box-shadow: inset 22px 0px 10px -20px rgba(0,0,0,0.15);
          -moz-box-shadow: inset 22px 0px 10px -20px rgba(0,0,0,0.15);
          box-shadow: inset 22px 0px 10px -20px rgba(0,0,0,0.15);

          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -o-transition: all 0.5s;
          transition: all 0.5s;
          transition-timing-function: ease-in-out;
          -moz-transition-timing-function: ease-in-out;
          -webkit-transition-timing-function: ease-in-out;
          -o-transition-timing-function: ease-in-out;


          .tools {
            width: 0;

            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;
            transition-timing-function: ease-in-out;
            -moz-transition-timing-function: ease-in-out;
            -webkit-transition-timing-function: ease-in-out;
            -o-transition-timing-function: ease-in-out;

            transition: width 1.5s;

            &.open {
              width: 70px;
            }
          }
        }
      }

      &.selected-row {
        //background-color: #fcc92f;
        //background-color: rgba(62, 92, 184, 0.8);
        background-color: var(--selected-row-background);

        &:hover {
          background-image: none;
          //background-color: rgba(62, 92, 184, 0.9);
          background-color: var(--selected-row-highlighted-background);
        }

        td {
          //color: #FFFFFF;
          color: var(--selected-row-font-color);
          background-color: transparent !important;
          //border-bottom: 1px solid #d3d3d3;
          border-bottom: 1px solid var(--selected-row-highlighted-border-color);

          button {
            color: #FFFFFF;
          }
        }

        .row-details-toggle-button {
          filter: invert(1);
        }
      }

      &:not(.selected-row):hover {
        background-image: none;
        //background-color: #fcfcfc;
        background-color: var(--row-highlighted-background);

        td {
          //border-bottom: 1px solid #ececec;
          border-bottom: 1px solid var(--row-highlighted-border-color);
        }
      }

      &.row-details {
        td {
          padding: 0;
        }
      }

      /*
      tr:nth-of-type(odd) {
        background-color: $figma-bg-color;
        th {
          background-color: darken($figma-bg-color, 15);
        }
        td {
          background-color: $figma-bg-color;
        }
      }

      tr:nth-of-type(even) {
        background-color: white;
        th {
          background-color: darken(white, 15);
        }
        td {
          background-color: white;
        }
      }
      */
    }
  }


  --bs-table-hover-color: #000000 !important;
  --bs-table-hover-bg: #fcfcfc !important;
}





::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}