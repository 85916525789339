.passengers-selection-dialog {
  display: flex;
  flex-direction: column;
  width: 520px;
  -webkit-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  min-height: 125px;
  padding: 0 20px 10px 20px;


  .passengers-selection-dialog__searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 40px;

    input {
      display: block;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      resize: none;

      &::-webkit-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-moz-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &:focus {
        color: #212529;
        background-color: #ffffff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
      }
    }
  }


  .ticket-selection-dialog__results {
    flex: 1;
    display: flex;
    flex-direction: column;


  }


  .passengers-selection-dialog__header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 34px;
    border-bottom: 1px solid #efefef;

    .chat-list-search-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 10px;
      height: 30px;
      width: 100%;
      /*background-color: #F5F5F5;*/
      background-image: linear-gradient(120deg, rgb(250, 250, 250) 0%, rgb(248, 248, 248) 100%);
      border-radius: 2px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.025) inset;

      input {
        flex: 1;
        background: transparent;
        border: none;
        outline: none;
        font-size: 12px;
        padding: 0 5px;
        color: #727272;

        &::placeholder {
          color: #b1b1b1;
          font-style: italic;
        }
      }
    }

    .passengers-selection-dialog__header-left-side {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
    }

    .passengers-selection-dialog__header-right-side {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
    }

  }

  .passengers-selection-dialog__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    flex: 1;
    max-height: 400px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    overflow: hidden;

    .passenger-list {
      padding: 5px;
      background-image: linear-gradient(120deg, rgb(250, 250, 250) 0%, rgb(248, 248, 248) 100%);

      .passenger-list-filter-error {
        color: #999999;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        margin: 10px;
      }

      .passenger-item-button {
        display: flex;
        flex-direction: row;
        outline: none;
        background: none;
        width: 100%;
        height: 50px;
        justify-self: start;
        align-self: start;
        border-radius: 5px;
        border: 1px solid #f2f2f2;
        border-top-width: 0;
        border-left-width: 0;
        border-right-width: 0;

        //&:first-child {
        margin-top: 5px;
        //}

        &:last-child {
          margin-bottom: 5px;
        }

        &:hover {
          background-color: #FFFFFF;
          box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
          //border: 1px solid transparent;
        }

        &.--active  {
          background-color: rgba(62, 92, 184, 0.2);
          border: 1px solid rgba(62, 92, 184, 0.2);
          box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
        }

        .passenger-item-button-checkbox,
        .passenger-item-button-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 100%;
        }

        .passenger-item-button-details {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;

          .passenger-item-button-details-row {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: start;

            &:first-child {
              align-items: end;
            }

            &:last-child {
              align-items: start;
            }

            .passenger-fio {
              font-size: 12px;
              color: #242A2A;
              font-weight: 500;
            }

            .passenger-dob {
              font-size: 11px;
              color: #8E8E90;
              font-weight: 500;
            }

          }
        }
      }
    }



    .no-passengers-message {
      margin: auto;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .no-passengers-message-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 60px;
        background-image: -moz-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
        background-image: -webkit-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
        background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
      }

      .no-passengers-message-caption {
        font-size: 12px;
        font-weight: 500;
        color: #27292A;
      }

      .no-passengers-message-content {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: #3C4145;
        max-width: 200px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .no-passengers-message-button {
        margin: 20px 0 10px 0;
      }
    }

    .loading-results-message {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin: 20px auto 20px auto;
      font-size: 12px;
      color: #A0A1A6;
    }
  }

  .passengers-selection-dialog__footer {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: center;
    gap: 5px;
    height: 45px;
  }





  .no-results-message {
    width: 90%;
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    font-size: 12px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 4px;
  }
}


.search-passengers-form-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;

  .search-passengers-form-filters-section {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: center;

  }

  .form-field {
    display: flex;
    flex-direction: row;

    .form-field-label {
      flex: 1;
      max-width: 100px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding: .375rem .75rem;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 400;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    input {
      flex: 1;
      display: block;
      padding: .375rem .75rem;
      font-weight: 400;
      color: #212529;
      font-size: 12px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: .25rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      outline: none !important;
      box-shadow: none !important;

      &::-webkit-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-moz-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &:focus {
        color: #212529;
        background-color: #ffffff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
      }
    }
  }
}