$bezier: cubic-bezier(.25, .8, .25, 1);

.md-slider {
  position: relative;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  overflow: visible;
  transition: opacity 0.4s $bezier, transform 0.4s $bezier, box-shadow 0.4s $bezier;
  min-width: fit-content;
  min-height: fit-content;

  .md-slider-pagination {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: max-content;
    min-height: max-content;
    overflow: visible;

    .dot {
      position: relative;
      background: rgba(black, 0.25);
      border-radius: 8px;
      height: 10px;
      margin: 0 4px;
      transform: scale(0.75);
      transition: transform 0.4s $bezier, background 0.4s $bezier;
      width: 10px;
      overflow: visible;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0);
        top: 50%;
        margin-top: -10px;
        left: 50%;
        margin-left: -10px;
      }

      &.--hidden {
        opacity: 0;
        pointer-events: none;
      }

      &.--active {
        background: rgb(62, 92, 184);
        transform: scale(1);
        transition-delay: 0.4s;
      }
    }
  }

  .md-slider-content {
    position: relative;
    align-items: center;
    flex: 1;
    display: flex;
    text-align: center;
    padding: 20px 0;
    min-width: max-content;
    min-height: max-content;

    .prev-screen,
    .next-screen {
      align-self: stretch;
      background: none;
      border: 0;
      margin-top: 40px;
      color: rgba(white, 0.25);
      cursor: pointer;
      flex: 0 0 auto;
      font-size: 24px;
      opacity: 1;
      outline: none;
      padding: 16px;
      transform: scale(1);
      transition: transform 0.4s $bezier, color 0.4s $bezier, opacity 0.4s $bezier;
      z-index: 1000;

      &:hover,
      &:active {
        color: white;
        transform-origin: center;
        transform: scale(1.25);
      }

      &:disabled {
        opacity: 0;
      }
    }

    .prev-screen {
      order: 1;
    }

    .next-screen {
      order: 3;
    }

    .screens {
      flex: 1;
      align-self: stretch;
      position: relative;
      margin: 0;
      padding: 0;
      order: 2;
      overflow: hidden;
      min-width: max-content;
      min-height: max-content;

      .screen {
        position: absolute;
        list-style-type: none;
        width: 100%;
        height: calc(100%);
        opacity: 0;
        transform: translateX(-100%);
        transition: all 0.4s $bezier;

        &.--active {
          position: relative;
          opacity: 1;
          transform: translateX(0) scale(1);
          transition-delay: 0.4s;

          ~ .screen {
            opacity: 0;
            transform: translateX(100%);
          }

          .media {
            .status-badge {
              opacity: 1;
              transform: scale(1.75);
            }

            &.logo {

              .logo {
                opacity: 0.8;
                transform: translateY(0);
                transition-delay: 0.6s;
              }

              .status-badge {
                transition-delay: 1s;
              }
            }

            &.books {
              .icon {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0.6s;

                &:nth-child(2) {
                  transition-delay: 0.725s;
                }

                &:nth-child(3) {
                  transition-delay: 0.850s;
                }
              }

              .status-badge {
                transition-delay: 1.4s;
              }
            }

            &.bars {
              .icon {
                opacity: 1;
                transform: translate(0) scale(1);
                transition-delay: 0.6s;

                &:nth-child(2) {
                  transition-delay: 1.050s;
                }

                &:nth-child(3) {
                  transition-delay: 0.925s;
                }

                &:nth-child(4) {
                  transition-delay: 0.8s;
                }
              }
            }

            &.files {
              .icon {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0.9s;

                &:nth-child(3) {
                  transition-delay: 0.8s;
                }

                &:nth-child(2) {
                  transition-delay: 0.7s;
                }

                &:nth-child(1) {
                  transition-delay: 0.6s;
                }
              }

              .status-badge {
                transition-delay: 1.6s;
              }
            }

            &.comm {
              .icon {
                opacity: 1;
                transform: scale(1);
                transition-delay: 0.6s;

                &:nth-child(2) {
                  transition-delay: 0.8s;
                }
              }

              .status-badge {
                transition-delay: 1.6s;
              }
            }
          }
        }
      }
    }
  }

  .md-slider-footer {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-around;
    padding: 0;
  }
}