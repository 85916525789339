.trip-page-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: auto;

  .trip-page-tabs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .trip-page-tabs-header {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: auto;
      align-items: end;
      overflow: hidden;

      button {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 180px;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.1);
        color: #2C2D2D;
        font-weight: 500;
        font-size: 12px;
        opacity: 0.5;

        &:not(.--selected):hover {
          opacity: 0.65;
        }

        &.--selected {
          background-color: #FFFFFF;
          opacity: 1.0;
          height: 42px;
        }
      }

      button:first-child {
        border-top-left-radius: 16px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.09), 0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
      }

      button:last-child {
        border-top-right-radius: 16px;
      }

    }

    .trip-page-tab {
      //border-bottom-left-radius: 16px;
      //border-bottom-right-radius: 16px;
      //background-color: #FFFFFF;
      //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.09), 0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
    }

  }

}


.trip-page-layout-switcher {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  height: auto;
  padding: 40px 30px 10px 30px;

  >:nth-child(1) {
    font-size: 12px;
  }

  >:nth-child(2) {
    position: relative;
    flex: 1;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #c5c5c5, #FFFFFF);
      background-size: 8px 2px, 8px 100%;
    }
  }

  >:nth-child(3) {
    font-size: 12px;
  }
}


.trip-page-search-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.09), 0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  z-index: 1000;

  .trip-page-search-form-header {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: start;
    gap: 5px;
    width: 100%;
    height: 60px;
    padding: 0 20px 15px 20px;

    .filter-menu-header {
      font-size: 12px;
      font-weight: 500;
      text-align: right;
      color: #000000;
      //min-width: 100px;
    }
    .filter-menu-content {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
    }
    .filter-menu-separator {
      width: 100%;
      height: 1px;
      background-color: #e3e3e3;
      margin: 10px 0;
    }
  }

  .trip-page-search-form-content {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0 30px 40px 30px;

    .trip-page-search-form-field {
      position: relative;
      min-width: 280px;
      height: 60px;
      border: 1px solid #d3d3d3;
      border-radius: 5px;

      &.--disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      label {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(0, -50%);
        text-transform: uppercase;
        color: #181717;
        font-size: 12px;
        font-weight: 500;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
        pointer-events: none;

        &.--displaced {
          font-size: 10px;
          top: 5px;
          transform: translate(0, 0);
        }
      }

      .trip-page-search-form-city-item {
        display: flex;
        flex-direction: column;
        width: 100%;

        .trip-page-search-form-city-item-header {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          color: #000000;
          height: 15px;
          font-size: 13px;
          font-weight: 500;
          width: 100%;

          .trip-page-search-form-city-item-code {
            flex: 1;
            color: #999999;
            font-size: 13px;
            font-weight: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .trip-page-search-form-city-item-details {
          display: flex;
          flex-direction: row;
          gap: 5px;
          color: #999999;
          font-weight: normal;
          height: 18px;
          font-size: 12px;
          text-transform: capitalize;
          width: 100%;

          .trip-page-search-form-city-item-flag {
            width: 20px;
            height: 20px;
          }

          .trip-page-search-form-city-item-details-text {
            flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

        }

      }

      input[type="date"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        color: transparent;
        padding: 6px 10px 4px 20px;
        font-size: 12px;
        font-weight: 500;

        &:focus,
        &.--focused {
          color: #000000;
        }
      }

      input[type="time"] {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 20px;
        background: none;
        border: none;
        outline: none;
        color: transparent;
        padding: 0 10px 0 24px;
        font-size: 12px;
        font-weight: 500;

        &::-webkit-calendar-picker-indicator {
          position: absolute;
          left: 8px;
        }

        &::-webkit-datetime-edit {
          position: relative;
          left: 5px;
        }

        &::-webkit-datetime-edit-fields-wrapper {
          position: relative;
          left: 5px;
        }

        &:focus,
        &.--focused {
          color: #000000;
        }
      }

      .trip-page-search-form-datetime-item {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20px 10px 6px 20px;
        pointer-events: none;

        &.--hidden {
          display: none;
        }

        .trip-page-search-form-datetime-item-date {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          color: #000000;
          font-size: 13px;
          font-weight: 500;
        }

        .trip-page-search-form-datetime-item-time {
          display: flex;
          flex-direction: row;
          gap: 5px;
          color: #999999;
          font-weight: normal;
          font-size: 12px;
        }
      }

      .departure-time-type,
      .return-time-type {
        font-size: 11px;
        text-align: right;
        width: 120px;
      }
      .departure-time-type-variants,
      .return-time-type-variants {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 10px;
      }

    }

    .trip-page-search-form-switch-cities-control {
      position: relative;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #d3d3d3;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 15px;
        height: 40px;
        border-radius: 50%;
        background: #FFFFFF;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .search-button[disabled] {
    background-color: #bbbbbb!important;
    opacity: 1!important;
  }
}

.trip-page-search-tools {
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 50px;
  margin-top: -10px;
  padding: 10px 18px 10px 38px;
  width: auto;

  .trip-page-search-providers {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    min-width: 400px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-color: #FFFFFF;
    padding: 0 18px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.09), 0px 2px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  }

}


.trip-page-no-results-message {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0 190px 40px;

  .trip-page-no-results-message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    margin: 0 0 10px 0;
    background-image: -moz-linear-gradient(to top, rgba(233, 233, 233, 0) 0%, rgba(233, 233, 233, 0) 10%, rgb(233, 233, 233, 1) 100%);
    background-image: -webkit-linear-gradient(to top, rgba(233, 233, 233, 0) 0%, rgba(233, 233, 233, 0) 10%, rgb(233, 233, 233, 1) 100%);
    background-image: linear-gradient(to top, rgba(233, 233, 233, 0) 0%, rgba(233, 233, 233, 0) 10%, rgb(233, 233, 233, 1) 100%);
  }

  .trip-page-no-results-message-caption {
    font-size: 14px;
    font-weight: 500;
    color: #27292A;
  }

  .trip-page-no-results-message-content {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: #3C4145;
    max-width: 260px;
    text-align: center;
  }
}


.trip-page-search-results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: -50px auto auto;
  padding-top: 50px;

  .trip-page-search-results-tickets {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 40px 40px 40px;
  }

  .loading-results-message {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 30px auto;
    font-size: 12px;
    color: #A0A1A6;
  }

  .no-results-message {
    width: 90%;
    text-align: center;
    margin: 30px auto;
    padding: 10px;
    font-size: 12px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 4px;
  }
}
