@import "../../../scss/mixins";

#section-1 h1 {
  font-size: 50px;
  font-weight: 300;
}
#section-1 p {
  font-size: 18px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
  padding: 0px;
}
#section-1 span {
  font-weight: 400;
  color: #0095f7;
  text-transform: uppercase;
  letter-spacing: .05em;
}
#section-2 p {
  font-size: 20px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
  padding: 0px;
}
#section-2 span {
  font-weight: 400;
  color: #0095f7;
  text-transform: uppercase;
  letter-spacing: .05em;
}
#section-3 {
  padding-top: 0;
}





.main-image-block {
  position: relative;
}
.sky-mask {
  margin: auto;
  width: 90%;
  height: 36vw;
  border-radius: 20rem;
  overflow: hidden;
}
.sky-mask img {
  width: 100vw;
  max-width: 1000px;
}
.airplane-container {
  margin: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.airplane-container {
  width: 100%;
}
.airplane-container img {
  width: 100%;
}





.ticket-search-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 0;
}





.title-block {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center!important;
}
.title-block p {
  font-size: 20px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
  text-align: center!important;
}
.title-block h2 {
  text-align: center!important;
}





.tiles-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.tiles-container .tile {
  width: 25%;
  height: 300px;
  display: flex;
  justify-content: center;
  padding: 20px 60px;
  position: relative;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  -webkit-box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0);
  box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0);
  -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
}
.tiles-container .tile:hover {
  background: #ffffff;
  -webkit-box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 29px 48px -14px rgba(0, 0, 0, 0.13);
}
.tile-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tile-inner i {
  width: 40px;
  height: 40px;
  margin: auto;
  display: block;
  position: relative;
  margin-bottom: 25px;
}
.tile-inner i svg {
  width: 100%;
  height: 100%;
}
.tile-inner p {
  flex: 1;
  opacity: 0.8;
  font-size: 1em !important;
  padding-bottom: 30px !important;
  max-width: 300px!important;
  text-align: center !important;
}
.tile-inner span {
  position: relative;
  opacity: 0;
  text-transform: capitalize!important;
  font-weight: 600!important;
  font-size: 0.8em!important;
  color: #3E5CB8!important;
  letter-spacing: 0.01em;
  -moz-transform: translateY(20px);
  -webkit-transform: translateY(20px);
  -o-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.tiles-container .tile:hover .tile-inner span {
  opacity: 1;
  -moz-transform: translateY(0px);
  -webkit-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}





.services-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.services-previews {
  position: relative;
  width: 340px;
  height: 400px;
}
.service-1-preview-mask {
  position: absolute;
  top: 0;
  right: 100px;
  width: 240px;
  height: 340px;
  overflow: hidden;
  border-radius: 180px;
}
.service-2-preview-mask {
  position: absolute;
  top: 120px;
  right: 40px;
  width: 180px;
  height: 280px;
  overflow: hidden;
  border-radius: 160px;
}
.service-1-preview-mask img {
  transform: translate(-140px, 0px);
  height: 100%;
  width: auto;
}
.service-2-preview-mask img {
  transform: translate(-90px, 0px);
  height: 100%;
  width: auto;
}
.services-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 50px;
}
.services-list {
  display: flex;
  flex-wrap: wrap;
}
.service-item {
  width: 50%;
  padding: 20px;
}
.service-item-title {
  font-weight: 500;
  color: #3a3f52;
  padding-bottom: 10px;
}
.service-item-description {
  color: #949AA8;
  font-size: 14px;
}





.rates-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: auto;
  height: auto;
  margin: auto;
  margin-bottom: 50px;
}

.rate-item {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 400px;
  padding: 20px 0;
  cursor: pointer;
  color: #292c31;
  -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.rate-item-header {
  height: 150px;
  background: transparent;
  background-image: radial-gradient(#e3e2e2 1px, transparent 0);
  background-size: 6px 6px;
  background-position: -19px -19px;
}

.rate-item-header h4 {
  padding: 20px;
  font-weight: 700;
  color: #3a3f52;
}

.rate-item-description {
  position: relative;
  height: auto;
  background: #FFFFFF;
  color: #3a3f52;
  padding: 10px 20px;
  line-height: 20px;
  display: flex;
  align-items: start;
  min-height: 80px;
  font-size: 14px;
  -webkit-transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1.5s cubic-bezier(0.19, 1, 0.22, 1);

  button {
    position: absolute;
    display: block;
    bottom: -15px;
    border: none;
    outline: none;
    padding: 5px 15px;
    background-color: #000000;
    color: #FFFFFF;
    border-radius: 15px;
    font-size: 12px;
  }
}

.rate-item:hover {
  background-color: #fcc92f;

  .rate-item-header {
    background-image: none;
  }

  .rate-item-description {
    background-color: #ffd34f;
  }
}

.rate-item-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 45px 10px 10px 10px;
  font-size: 12px;
  color: #3a3f52;
}




#section-1,
#section-2,
#section-3,
#section-4,
#section-5 {
  border: none;
}


#section-1,
#section-3,
#section-5 {
  background: #FFFFFF;
}




@include desktop {
  #section-1,
  #section-2 {
    padding-top: 100px;
  }

  #section-3 {
    padding-bottom: 20px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 50px;
  }
  .sky-mask {
    width: 1000px;
    height: 420px;
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 1180px;
  }
  .tiles-container .tile {
    width: 25%;
    height: 300px;
  }
  .rate-item {
    width: 200px;
  }
}

@include laptop {
  #section-1,
  #section-2 {
    padding-top: 100px;
  }

  #section-3 {
    padding-bottom: 20px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 50px;
  }
  .sky-mask {
    width: 76vw;
    height: calc(76vw * 0.42);
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 90vw;
  }
  .ticket-search-container {
    margin-bottom: 0;
  }
  .tiles-container .tile {
    width: 50%;
    height: 300px;
  }
  .rate-item {
    width: 200px;
  }
}

@include tabletPortrait {
  #section-1,
  #section-2 {
    padding-top: 100px;
  }

  #section-3 {
    padding-bottom: 10px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 50px;
  }
  .sky-mask {
    width: 76vw;
    height: calc(76vw * 0.42);
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 90vw;
  }
  .ticket-search-container {
    margin-bottom: 20px;
  }
  .tiles-container .tile {
    width: 50%;
    height: 300px;
  }
  .services-container {
    flex-direction: column-reverse;
  }
  .services-title {
    text-align: center;
  }
  .services-previews {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .services-content {
    width: 100%;
  }
  .rate-item {
    width: 200px;
  }
}

@include tabletLandscape {
  #section-1,
  #section-2 {
    padding-top: 100px;
  }

  #section-3 {
    padding-bottom: 10px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 50px;
  }
  .sky-mask {
    width: 76vw;
    height: calc(76vw * 0.42);
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 90vw;
  }
  .ticket-search-container {
    margin-bottom: 20px;
  }
  .tiles-container .tile {
    width: 50%;
    height: 300px;
  }
  .services-container {
    flex-direction: column-reverse;
  }
  .services-title {
    text-align: center;
  }
  .services-previews {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .services-content {
    width: 100%;
  }
  .rate-item {
    width: 200px;
  }
}

@include mobilesLandscape {
  #section-1 {
    padding-top: 100px;
  }

  #section-2 {
    padding-top: 70px;
  }

  #section-3 {
    padding-bottom: 10px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 50px;
  }
  .sky-mask {
    width: 76vw;
    height: calc(76vw * 0.42);
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 90vw;
  }
  .ticket-search-container {
    margin-bottom: 20px;
  }
  .tiles-container .tile {
    width: 100%;
    height: 220px;
  }
  .services-container {
    flex-direction: column-reverse;
  }
  .services-title {
    text-align: center;
  }
  .services-previews {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .services-content {
    width: 100%;
  }
  .service-item {
    width: 100%;
  }
  .rate-item {
    width: 100%;
    height: 320px;
  }
  .rate-item-header {
    height: 100px;
  }
  .rate-item-description {
    min-height: 70px;
  }
  .rate-item-content {
    padding: 35px 10px 10px 10px;
  }
}

@include mobilesPortrait {
  #section-1 {
    padding-top: 100px;
  }
  #section-2 {
    padding-top: 70px;
  }

  #section-3 {
    padding-bottom: 10px;
  }

  #section-3,
  #section-4,
  #section-5 {
    padding-top: 10px;
  }
  .sky-mask {
    width: 76vw;
    height: calc(76vw * 0.42);
  }
  .airplane-container {
    width: auto;
  }
  .airplane-container img {
    width: 90vw;
  }
  .ticket-search-container {
    margin-bottom: 20px;
  }
  .tiles-container .tile {
    width: 100%;
    height: 290px;
  }
  .services-container {
    flex-direction: column-reverse;
  }
  .services-title {
    text-align: center;
  }
  .services-previews {
    max-width: 100%;
    height: 340px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 0;
  }
  .services-content {
    width: 100%;
  }
  .service-item {
    width: 100%;
  }
  .rate-item {
    width: 100%;
    height: 320px;
  }
  .rate-item-header {
    height: 100px;
  }
  .rate-item-description {
    min-height: 70px;
  }
  .rate-item-content {
    padding: 35px 10px 10px 10px;
  }


  .service-1-preview-mask {
    position: absolute;
    top: 0;
    left: 50px;
    width: 160px;
    height: 260px;
    overflow: hidden;
    border-radius: 180px;
  }
  .service-2-preview-mask {
    position: absolute;
    top: 80px;
    left: 140px;
    width: 140px;
    height: 220px;
    overflow: hidden;
    border-radius: 160px;
  }
  .service-1-preview-mask img {
    transform: translate(-110px, 0px);
    height: 100%;
    width: auto;
  }
  .service-2-preview-mask img {
    transform: translate(-70px, 0px);
    height: 100%;
    width: auto;
  }
}