.orders-results {
    display: flex!important;
    flex-direction: column!important;
    padding: 24px 22px 24px 22px !important;
    gap: 6px!important;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;

    .orders-results-header {
        display: flex!important;
        flex-direction: row!important;
    }
    .orders-results-caption {
        display: flex;
        align-items: center;
        font-size: 18px!important;
        font-weight: 500!important;
        color: #161A1F!important;
    }

    .orders-results-caption span {
        padding-left: 5px;
        font-size: 18px!important;
        font-weight: 300!important;
        color: #A9ADB1!important;
    }

    .orders-results-tools {
        flex: 1;
        display: flex!important;
        flex-direction: row!important;
        align-items: center;
        justify-content: end;
        gap: 10px;
    }

    .orders-results-preloader {
        margin-left: 5px !important;
        display: flex;
        align-items: center;

        .orders-results-preloader-icon {
            width: 16px !important;
            height: 16px !important;
            border-width: 2px !important;
        }
        .orders-results-preloader-text {
            font-size: 12px !important;
        }
    }

    .empty-search-result-message {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        padding: 10px;
        font-size: 12px!important;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        border-radius: 4px;
    }
}

.doc-state-indicator {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    vertical-align: bottom;
    text-align: center;
    background: transparent;
    padding: 1px 10px 0 10px;
    border-radius: 12px;
    height: 24px;
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    border: 1px solid #CCCCCC;
}

.doc-state-indicator.--success {
    color: #74B100;
    border: 1px solid #74B100;
}

//.selected-row .doc-state-indicator,
//.selected-row .doc-state-indicator.--success{
//    color: #FFFFFF;
//    border: 1px solid #FFFFFF;
//
//    svg path {
//        stroke: #FFFFFF!important;
//    }
//}

.doc-state-indicator.--progress {
    color: rgb(100, 100, 100);
    border: 1px solid rgba(200, 200, 200, 0.25);
    background: rgba(200, 200, 200, 0.25);
    background: linear-gradient(110deg, rgba(200, 200, 200, 0.25) 8%, rgba(255, 255, 255, 0.8) 18%, rgba(200, 200, 200, 0.25) 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

//.selected-row .doc-state-indicator.--progress {
//    color: #FFFFFF;
//
//    svg path {
//        fill: #FFFFFF!important;
//    }
//}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.download-documents-button {
    background: none;
    border: none;
    outline: none;
    color: #000000;
    text-decoration: underline;
}



.toggle-tools-button {
    background: none;
    outline: none;
    border: none;
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 5px;
    transform: scale(0.65);

    span {
        background-color: #888888;
        position: absolute;
        border-radius: 2px;
        transition: .3s cubic-bezier(.8, .5, .2, 1.4);
        width: 100%;
        height: 2px;
    }

    span:nth-child(1) {
        top: 1px;
        left: 0px;
    }
    &.open span:nth-child(1) {
        transform: rotate(45deg) scaleX(0.7);
        top: 4px;
        left: 0;
    }

    span:nth-child(2) {
        top: 9px;
        left: 0px;
    }
    &.open span:nth-child(2) {
        transform: scale(0);
        transition-duration: 50ms;
    }

    span:nth-child(3) {
        bottom: 1px;
        left: 0px;
    }
    &.open span:nth-child(3) {
        transform: rotate(-45deg) scaleX(0.7);
        top: 13PX;
        left: 0;
    }

}

//tr.selected-row .toggle-tools-button span {
//    background-color: #FFFFFF;
//}