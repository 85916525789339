@import "../../../scss/extends";
@import "../../../scss/default_variables";

.asdf {
  @extend .common-vertical-border;
  color: black;
}

.company-profile-container {
  @extend .common-flex-column-container;
  gap: 6px;
}

.company-profile-info-row {
  @extend .common-flex-row-container;
  padding: 10px 16px;
  font-size: 0.8rem;
  gap: 6px;
}
.company-profile-info-block {
  @extend .common-flex-column-container;
  padding: 0 8px ;
  label {
    color:gray;

  }
  div{
    font-size:0.9rem;
    font-weight:500;
  }
  div[typeof="date"]{
    label{
      color:gray;
    }
  }

}

.my-profile-vertical-border{
  color:gray;
  border-left:3px solid;
  border-image: linear-gradient(to bottom, lightgray, $primary-color) 0 1;
}

.my-profile-info-sub-block {
  position: relative;
  display: inline-block;

  label {
    @extend .common-label;
    visibility: hidden;
    position: absolute;
    font-size: 1rem;
    background-color: #c9f76f;
    padding: 2px 5px;
    border-radius: 5px;
  }

  div {
    background-color: #4680ce;
    color: white;
    border-radius: 5px;
    padding: 5px;
  }

  &:hover {
    label {
      visibility: visible;
      transform: scale(0.75) translateY(-80%) translateX(5px);
      transition: 1s;
    }

  }
}
