
.md-list {
  position: relative;
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: column;


  --item-border-color: #F0F0F1;
  --item-background-color: #FFFFFF;
  --item-font-color: #161a1f;
  --item-box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);

  --item-highlighted-border-color: #F0F0F1;
  --item-highlighted-background: rgba(200, 200, 200, 0.1);
  --item-highlighted-font-color: #161a1f;
  --item-highlighted-box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);

  --selected-item-border-color: rgba(62, 92, 184, 0.8);
  --selected-item-background-color: rgba(62, 92, 184, 0.08);
  --selected-item-font-color: var(--item-font-color);
  --selected-item-box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);


  ul {
    position: relative;
    display: flex;
    flex-direction: column;
    //border-left-width: 0;
    //border-right-width: 0;
    //padding: 10px 0;
    padding: 5px;
    background-image: linear-gradient(120deg, rgb(250, 250, 250) 0%, rgb(248, 248, 248) 100%);
    overflow: hidden;

    li {
      display: flex;
      flex-direction: row;
      background: none;
      width: 100%;
      height: auto;
      justify-self: start;
      align-self: start;
      border-radius: 5px;
      border: 1px solid #f2f2f2;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      margin-top: 5px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 5px;
      }

      &:hover {
        background-color: #FFFFFF;
        box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
        //border: 1px solid transparent;
      }

      &.--selected-item  {
        background-color: rgba(62, 92, 184, 0.2);
        border: 1px solid rgba(62, 92, 184, 0.2);
        box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
      }

      .md-list-item-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
      }
    }

    //li {
    //  flex: 1;
    //  position: relative;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  border: 1px solid var(--item-border-color);
    //  border-radius: 5px;
    //  background-color: var(--item-background-color);
    //  color: var(--item-font-color);
    //  box-shadow: var(--item-box-shadow);
    //  padding: 10px;
    //  font-size: 12px;
    //  text-align: center;
    //  cursor: pointer;
    //
    //  &.--selected-item {
    //    border-color: var(--selected-item-border-color);
    //    background-color: var(--selected-item-background-color);
    //    color: var(--selected-item-font-color);
    //    box-shadow: var(--selected-item-box-shadow);
    //  }
    //
    //  &.--selected-item:hover {
    //    border: 1px solid var(--selected-item-border-color);
    //    background-color: var(--selected-item-background-color);
    //    color: var(--selected-item-font-color);
    //  }
    //
    //  &:not(.--selected-item):hover {
    //    border: 1px solid var(--item-highlighted-border-color);
    //    background-color: var(--item-highlighted-background);
    //    box-shadow: var(--item-highlighted-box-shadow);
    //  }
    //}
  }
}