.company-selection-dialog {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  -webkit-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: max-height 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);

  .company-selection-dialog__searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -moz-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);

    .search-input {
      display: block;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      resize: none;

      &::-webkit-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-moz-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &::placeholder {
        font-style: italic;
        color: #95989a;
        opacity: 1;
      }

      &:focus {
        color: #212529;
        background-color: #ffffff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
      }
    }
  }

  .company-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    justify-content: start;

    .company-details-item {
      width: 100%;
      gap: 5px;
      text-align: left;
      max-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;

      span:nth-child(1) {
        font-weight: 500;
        color: rgb(62, 92, 184);
      }
    }
  }

  .company-selection-dialog__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .loading-results-message {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: #A0A1A6;
  }

  .no-results-message {
    width: 90%;
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    font-size: 12px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 4px;
  }
}