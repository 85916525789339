$bezier: cubic-bezier(.25, .8, .25, 1);

.chat {
  display: flex;
  flex-direction: column;
  flex: 1;


  .chat-slider-container {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .chat-slider-slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      transition: opacity 0.4s $bezier, transform 0.4s $bezier, box-shadow 0.4s $bezier;
      transform: translateX(100%);

      &[data-type="previous"] {
        transform: scale(0.85);
        opacity: 0;
      }

      &[data-type="active"] {
        transform: translateX(0%);

        &:before {
          content: '';
          position: absolute;
          left: -20px;
          top: 0;
          width: 20px;
          height: 100%;
          display: block;
          -webkit-box-shadow: inset 22px 0px 10px -20px rgba(0, 0, 0, 0.15);
          -moz-box-shadow: inset 22px 0px 10px -20px rgba(0, 0, 0, 0.15);
          box-shadow: inset 22px 0px 10px -20px rgba(0, 0, 0, 0.15);
          transform: rotate(180deg);
        }

      }
    }

  }


  .chat-list-layout {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .chat-list-layout-header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: 100%;
      min-height: 50px;
      padding: 0;
      //background: -webkit-linear-gradient(to right, rgba(236, 233, 230, 0.8), rgba(255, 255, 255, 0.8)); /* Chrome 10-25, Safari 5.1-6 */
      //background: linear-gradient(to right, rgba(236, 233, 230, 0.8), rgba(255, 255, 255, 0.8)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background-color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(200, 200, 200, 0.2);
      /*backdrop-filter: blur(5px);*/
      border-bottom: 1px solid #F2F1F0;

      .chat-list-layout-header-title-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 10px;

        .chat-back-button {
          width: 24px;
          height: 24px;
          background: none;
          outline: none;
          border: none;
        }

        .chat-details {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #727272;
          padding: 0 5px;
          gap: 10px;

          .chat-details-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: relative;
            overflow: hidden;

            img {
              min-height: 100%;
              min-width: 100%;
            }
          }

          .chat-details-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;

            .chat-description-name {
              font-size: 12px;
              font-weight: 700;
            }

            .chat-description-description {
              font-size: 11px;
            }
          }

        }

        .chat-description {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          padding: 0 5px;

          .chat-status {
            font-size: 10px;
            font-weight: 300;
            padding: 2px 8px;
            color: #5C5B5A;
            background-color: #E6E2DE;
            border-radius: 10px;
            height: auto;
            margin-left: 15px;
          }
        }

        .notification-container {
          position: relative;

          &.--indicated:after {
            content: '';
            position: absolute;
            display: block;
            top: 5px;
            right: 5px;
            width: 10px;
            height: 10px;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            background-color: #F96E00;
          }
        }

        .chat-close-button {
          width: 24px;
          height: 24px;
          background: none;
          outline: none;
          border: none;
        }
      }

      .chat-list-layout-header-tools-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0;

        .chat-list-search-bar {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 10px;
          margin: 10px 0;
          height: 30px;
          width: 90%;
          background-color: #F5F5F5;
          border-radius: 12px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.025) inset;

          input {
            flex: 1;
            background: transparent;
            border: none;
            outline: none;
            font-size: 12px;
            padding: 0 5px;
            color: #727272;

            &::placeholder {
              color: #b1b1b1;
              font-style: italic;
            }
          }
        }
      }
    }

    .chat-list-layout-content {
      flex: 1;
      position: relative;
      width: 100%;
      background: linear-gradient(to bottom, rgba(220, 220, 220, 0.1), rgba(255, 255, 255, 1.0));
      overflow: hidden;

      .chat-list-item {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 5px 10px 0 10px;
        //border-bottom: 1px solid #efefef;
        transition: all 250ms ease-out;
        cursor: pointer;

        .chat-list-item-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          overflow: hidden;
          border-radius: 50px;
          //background-image: -moz-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
          //background-image: -webkit-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
          //background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
          border: 1px solid #FFFFFF;
          box-shadow: 0px 0px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 10px 0px rgba(0, 0, 0, 0.05), 0px 1px 15px 0px rgba(0, 0, 0, 0.08);

          img {
            min-height: 100%;
            min-width: 100%;
          }

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 300;
            font-size: 14px;
            //background-color: rgb(229 168 80);
            color: #FFFFFF;
            width: 100%;
            height: 100%;
          }
        }

        .chat-list-item-details {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: start;
          height: 46px;
          padding: 0 0 0 10px;
          border-bottom: 1px solid #efefef;

          .chat-title {
            color: #000000;
            font-size: 12px;
            font-weight: 500;
          }

          .chat-last-message {
            color: #888887;
            font-size: 11px;
            white-space: pre-wrap;
            overflow: hidden;
            max-height: 18px;

            .no-messages-text {
              color: #CCCCCC;
              font-style: italic;
            }
          }
        }

        .chat-list-item-status {
          display: flex;
          flex-direction: column;
          height: 46px;
          align-items: end;
          border-bottom: 1px solid #efefef;

          .chat-last-message-time {
            color: #888887;
            font-size: 10px;
            margin-bottom: 2px;
            text-transform: capitalize;
          }

          .chat-unread-messages {
            color: #503213;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            background-color: #FAE4CB;
            padding: 2px 6px;
            min-width: 6px;
            min-height: 16px;
            border-radius: 4px;
            text-align: center;
          }
        }
      }
    }
  }


  .chat-layout {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

    .chat-layout-header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: 100%;
      min-height: 50px;
      padding: 0;
      //background: -webkit-linear-gradient(to right, rgba(236, 233, 230, 0.8), rgba(255, 255, 255, 0.8)); /* Chrome 10-25, Safari 5.1-6 */
      //background: linear-gradient(to right, rgba(236, 233, 230, 0.8), rgba(255, 255, 255, 0.8)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background-color: rgba(255, 255, 255, 0.8);
      /*backdrop-filter: blur(5px);*/
      border-bottom: 1px solid #F2F1F0;

      .chat-layout-header-title-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px 10px;

        .chat-back-button {
          width: 24px;
          height: 24px;
          background: none;
          outline: none;
          border: none;
        }

        .chat-details {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #727272;
          padding: 0 5px;
          gap: 10px;

          .chat-details-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            border: 1px solid #FFFFFF;
            box-shadow: 0px 0px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 10px 0px rgba(0, 0, 0, 0.05), 0px 1px 15px 0px rgba(0, 0, 0, 0.08);

            img {
              min-height: 100%;
              min-width: 100%;
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 300;
              font-size: 12px;
              //background-color: rgb(229 168 80);
              color: #FFFFFF;
              width: 100%;
              height: 100%;
            }
          }

          .chat-details-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;

            .chat-description-name {
              font-size: 12px;
              font-weight: 700;
            }

            .chat-description-description {
              font-size: 11px;
            }
          }

        }

        .chat-description {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          padding: 0 5px;

          .chat-status {
            font-size: 10px;
            font-weight: 300;
            padding: 2px 8px;
            color: #5C5B5A;
            background-color: #E6E2DE;
            border-radius: 10px;
            height: auto;
            margin-left: 15px;
          }
        }

        .notification-container {
          position: relative;

          &.--indicated:after {
            content: '';
            position: absolute;
            display: block;
            top: 5px;
            right: 5px;
            width: 10px;
            height: 10px;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            background-color: #F96E00;
          }
        }

        .chat-close-button {
          width: 24px;
          height: 24px;
          background: none;
          outline: none;
          border: none;
        }
      }
    }


    .chat-layout-messages {
      flex: 1;
      position: relative;
      list-style: none;
      overflow-y: auto;
      overflow-x: hidden;
      background: linear-gradient(to bottom, rgba(220, 220, 220, 0.1), rgba(255, 255, 255, 1.0));

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        //margin-top: 104px;
        //margin-bottom: 94px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.2);
      }

      .loading-messages-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        transform: translate(0, 50%);

        span {
          color: #999999;
          font-size: 12px;
        }
      }

      li {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: 10px 0 0 0;
        padding: 0 10px;

        &:nth-child(1) {
          margin-top: 20px;
        }

        &:last-child {
          margin-bottom: 20px;
        }

        &.messages-gap {
          position: relative;
          color: #b9b9b9;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          text-wrap: nowrap;
          text-transform: lowercase;

          &:before {
            content: '';
            margin-right: 5px;
            right: 100%;
            width: 200px;
            height: 1px;
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
            background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
          }

          &:after {
            content: '';
            margin-left: 5px;
            left: 100%;
            width: 200px;
            height: 1px;
            background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
            background: linear-gradient(to right, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
          }
        }

        .message-item {
          max-width: 80%;
          display: flex;
          flex-direction: column;
          gap: 2px;

          .message-item-header {
            padding-left: 40px;
            color: #999999;
            font-size: 12px;
          }

          .message-item-content {
            display: flex;
            flex-direction: row;

            .user-avatar-container {
              position: relative;
              width: 30px;
              align-self: start;
              margin-right: 10px;

              .user-avatar-wrapper {
                position: relative;
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #FFFFFF;
                box-shadow: 0px 0px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 10px 0px rgba(0, 0, 0, 0.05), 0px 1px 15px 0px rgba(0, 0, 0, 0.08);
                border-radius: 50%;
                overflow: hidden;

                img {
                  min-height: 100%;
                  min-width: 100%;
                }

                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 300;
                  font-size: 12px;
                  //background-color: rgb(229 168 80);
                  color: #FFFFFF;
                  width: 100%;
                  height: 100%;
                }
              }

            }

            .message {
              white-space: pre-wrap;

              .message-time {
                margin-left: 20px;
                float: right;
                transform: translate(5px, 12px);
                font-size: 11px;
                color: #707070;
              }
            }
          }
        }


        &.other {

          .message {
            color: #000000;
            word-wrap: break-word;
            background-color: #F2F2F2;
            padding: 14px;
            border-radius: 0 10px 10px 10px;
            max-width: 80%;
            font-size: 12px;
          }
        }

        &.self {
          justify-content: end;

          .message {
            color: #000000;
            word-wrap: break-word;
            background-color: #FAE4CB;
            padding: 14px;
            border-radius: 10px 10px 0px 10px;
            font-size: 12px;
          }

          .message-status {
            align-self: end;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            border: 2px solid #FAE4CB;

            &.--read {
              background-color: #FAE4CB;

              .checkmark:nth-child(2) {
                display: block;
              }
            }

            .checkmark {
              transform: translate(0, -1px) rotate(45deg);
              height: 8px;
              width: 4px;
              border-bottom: 1px solid #765C3D;
              border-right: 1px solid #765C3D;
            }

            .checkmark:nth-child(1) {
              display: none;
            }

            .checkmark:nth-child(2) {
              display: block;
            }


          }
        }
      }
    }

    .printing-status {
      z-index: 1000;
      color: #bdbdbd;
      position: absolute;
      left: 12px;
      bottom: 110px;
      font-size: 12px;
      font-style: italic;
    }

    .chat-footer {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: start;
      border-radius: 8px;
      margin: 10px;
      padding: 10px 8px;
      height: auto;
      background-image: linear-gradient(90deg, rgba(220, 220, 220, 0.4) 0%, rgba(240, 240, 240, 0.6) 100%);
      background: -webkit-linear-gradient(to right, rgba(220, 220, 220, 0.4), rgba(240, 240, 240, 0.6)); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, rgba(220, 220, 220, 0.4), rgba(240, 240, 240, 0.6)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      backdrop-filter: blur(5px);
      -webkit-box-shadow: 0px 0px 2px 0px rgba(100, 100, 100, 0.1) inset;
      -moz-box-shadow: 0px 0px 2px 0px rgba(100, 100, 100, 0.1) inset;
      box-shadow: 0px 0px 2px 0px rgba(100, 100, 100, 0.1) inset;
      border-top: 1px solid #F2F1F0;

      textarea {
        font-size: 13px;
        border: none;
        outline: none;
        background: none;
        width: 100%;
        resize: none;
        color: #727272;
      }

      .chat-footer-controls {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 5px;

        .chat-footer-tools-block {
          flex: 1;
          display: flex;
          flex-direction: row;
        }

        .chat-footer-send-block {
          width: auto;
        }
      }
    }
  }


  .reconnecting-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    color: #999999;
  }

}


.no-items-message {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50%;
  transform: translate(0, -25%);

  .no-items-message-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-image: -moz-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
    background-image: -webkit-linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
    background-image: linear-gradient(to top, rgba(243, 243, 243, 0) 0%, rgba(243, 243, 243, 0) 10%, rgb(243, 243, 243, 1) 100%);
  }

  .no-items-message-caption {
    font-size: 14px;
    font-weight: 500;
    color: #27292A;
  }

  .no-items-message-content {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
    color: #3C4145;
    max-width: 200px;
    text-align: center;
  }

  .no-items-message-button {
    margin-top: 40px;
  }
}