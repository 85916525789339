.md-accordion {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 29px -16px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 10px 29px -16px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 29px -16px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 5px;
  overflow: hidden;

  .md-accordion-section {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    //border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;

    .md-accordion-section-header {
      z-index: 1;

      .md-accordion-section-header-button {
        background: none;
        border: none;
        outline: none;
        width: 100%;
        height: 50px;
        font-size: 12px;
        color: #000;
        padding: 0;
        -webkit-box-shadow: 0px 8px 18px -16px rgba(0, 0, 0, 0.19);
        -moz-box-shadow: 0px 8px 18px -16px rgba(0, 0, 0, 0.19);
        box-shadow: 0px 8px 18px -16px rgba(0, 0, 0, 0.19);

        //&:focus {
        //  -webkit-box-shadow: 0px 12px 18px -16px rgba(0, 0, 0, 0.37) !important;
        //  -moz-box-shadow: 0px 12px 18px -16px rgba(0, 0, 0, 0.37) !important;
        //  box-shadow: 0px 12px 18px -16px rgba(0, 0, 0, 0.37) !important;
        //}
      }
    }

    .md-accordion-section-content {
      background: rgba(0, 0, 0, 0.02);
      max-height: 0px;
      -webkit-transition: max-height 0.35s ease;
      -moz-transition: max-height 0.35s ease;
      -o-transition: max-height 0.35s ease;
      -ms-transition: max-height 0.35s ease;
      transition: max-height 0.35s ease;
      overflow: hidden;

      &.--open {
        max-height: max-content;
      }
    }
  }
}