@import "../../scss/mixins.scss";
@import "../../scss/default_variables.scss";

.cabinet-layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #FAFAFA;
}

.cabinet-view-container {
  flex: 1;
  width: 100%;
}

.cabinet-view-content {
  position: relative;
  width: 100%;
  margin: auto;
  padding-top: 20px;
}

.document-search-filters,
.active-documet-details {
  border: none;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.03);
  margin-bottom: 10px;
  padding: 10px !important;
}

.active-documet-details button {
  border: none!important;
  outline: none!important;
  box-shadow: none!important;
  background-color: #FFFFFF!important;
  background: #FFFFFF!important;

  .input-group-text {
    background-color: #FFFFFF!important;
    background: #FFFFFF!important;
    font-size: 14px!important;
  }
}

.accordion-item {
  border: none!important;
}

#flush-collapseOne {
  background: #FFFFFF!important;
  background-color: #FFFFFF!important;
}

.layout-container {
  width: 100%;
  height: 100%;
  background-color: $figma-bg-color;
}

.test-style {
  background-color: #1c1d1c;
  color: #1976d2;
}

.layout-container-content {
  height: 100vh;
  width: 100vw;
  top: 110px;
  background-color: $figma-bg-color;
  //color: #1976d2;
}

.layout-header {
  position: fixed;
  z-index: 1;
  background-color: $secondary-background;
  width: 100%;
  height: 10%;
  margin: 0;
  padding: 0;
}

.layout-second-section {
  position: fixed;
  top: 10%;
  //margin-top: 6px; //hardcode
  height: 90%;
  width: 100%;
  display: flex;
}

.layout-main {
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Add vertical scrollbar */
  //display: flex;
  //background-color: $figma-bg-color;
}

.layout-sidebar {
  //position: relative;
  padding: 0%;
  width: 16%;
  height: 100%; //dont use
  background-color: $secondary-background;
  align-items: stretch;
  color: brown;
}

.layout-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  //width: 100%;
  background-color: red;
  color: white;
  text-align: center;
  //flex: 0;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: calc(1.5rem * .5);
  padding-left: calc(1.5rem * .5);
  margin-right: auto;
  margin-left: auto;
}


.sub-header {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7px 5px;
  //background-image: linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%);
  //background-color: rgb(235, 237, 238);
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  ul li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    //background-color: #ededed;
    background-color: transparent;
    color: #7f7f7f;
    white-space: nowrap;
    font-size: 11px;
    text-decoration: none;
    border-radius: 12px;

    a {
      padding: 4px 10px;
      color: #7f7f7f;
      text-decoration: none;
      margin-top: 2px;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.--active {
      //background-color: #3E5CB8;
      background-color: #ededed;

      a {
        color: #000000;
      }
    }
}

.sub-header-item {

  }
}

//.sub-header-item:after {
//  content: "";
//  position: absolute;
//  top: 0;
//  left: 100%;
//  border-width: 1em 0.25em 1em 0.5em;
//  border-style: solid;
//  border-color: transparent transparent transparent #e5e5e5;
//}
//
//.sub-header-item:not(:nth-child(1)):before {
//  content: "";
//  position: absolute;
//  top: 0;
//  right: 100%;
//  border-width: 1em 0.25em 1em 0.5em;
//  border-style: solid;
//  border-color: #e5e5e5 #e5e5e5 #e5e5e5 transparent;
//}

.sub-header button {
  border: none;
  outline: none;
  background-color: transparent;
  color: $secondary-color;
  font-size: 0.8rem;
  transition: background-color 0.5s;
  padding: 10px;

  &:hover {
    background-color: transparent;
    color: #000000;
    font-weight: 500;
  }
}

.sub-header button.active-button {
  color: #000000;
  font-weight: 500;
}

@media (max-width: 991px) {
  //.sub-header {
  //  display: none;
  //}
}



@include laptop {
  .test-style {
    background-color: blue;
  }
  .layout-sidebar {
    // display: none;
    //background-color: #7939ba;
    width: 16%;
  }
}

@include tablet {
  .layout-header {
    height: 60px;
  }
  .layout-container-content {
    top: 50px;
  }
  .layout-sidebar {
    top: 50px;
    display: none;
  }
  .layout-main {
    max-width: 100%;
  }
  .layout-second-section {
    top: 50px;
  }
}

@include iphoneSe {
  .layout-header {
    height: 60px;
  }

  .layout-sidebar {
    //display: none;
  }
}




@media (min-width: 992px) {
  .cabinet-view-content  {
    max-width: 960px;
  }
  .header-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .cabinet-view-content  {
    max-width: 1140px;
  }
  .header-container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .cabinet-view-content  {
    max-width: 1320px;
  }
  .header-container {
    max-width: 1320px;
  }
}




/*
  Device = Desktops
  Screen = 1281px to higher resolution desktops
*/
@include desktop {

}

/*
  Device = Laptops, Desktops
  Screen = from 1025px to 1280px
*/
@include laptop {

}

/*
  Device = Tablets, Ipads (portrait)
  Screen = from 768px to 1024px
*/
@include tabletPortrait {

}

/*
  Device = Tablets, Ipads (landscape)
  Screen = from 768px to 1024px
*/
@include tabletLandscape {

}

/*
  Device = Low Resolution Tablets, Mobiles (Landscape)
  Screen = from 481px to 767px
*/
@include mobilesLandscape {

}

/*
  Device = Most of the Smartphones Mobiles (Portrait)
  Screen = from 320px to 479px
*/
@include mobilesPortrait {

}

@media (max-width: 991px) {
  //.cabinet-view-container {
  //  padding-top: 70px;
  //}
  .sub-header {
    margin-top: 70px;
  }
}
