@import "../../../../scss/default_variables.scss";

.dropdown {
  position: relative;
  display: inline-block;
  text-decoration: underline;

  &:hover .dropdown-content {
    display: block;
    color: $cabinet-header-color;
  }

  &:focus-within .dropdown-content {
    display: block;
    color: $cabinet-header-color;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 10px;
  border: 1px solid black;
  background-color: #f4f4f4;
  //margin-top: 2px;
  min-width: 240px;
  padding: 12px 16px 12px 16px;
  z-index: 1;

  li {
    padding: 3px 4px;
    border-bottom: 1px solid lightgrey;
    color: darken(grey, 10);
    font-weight: 400;

    span [type="selectItem"] {
      font-weight: 100;
      margin-left: 3px;
      margin-bottom: 2px;
    }

    &:hover {
      background-color: $primary-color;
      border-radius: 5px;
      color: whitesmoke;

      span[id="selectItem"] {
        color: whitesmoke;
      }
    }
  }
}

.company-fee-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
//background-color: palegoldenrod;
}
.company-fee-info-block {
  display: flex;
  flex-direction: column;
  //background-color: hotpink;
  div[typeof="fee-info-title"]{
    color:darkslategrey;
    font-weight:500;
    padding-left: 8px;
    font-size: 1rem;
  }

}
