.document-results {
    display: flex!important;
    flex-direction: column!important;
    padding: 24px 22px 12px 22px !important;
    gap: 6px!important;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
    margin-bottom: 10px;

    .document-results-header {
        display: flex!important;
        flex-direction: row!important;
    }
    .document-results-caption {
        display: flex;
        align-items: center;
        font-size: 18px!important;
        font-weight: 500!important;
        color: #161A1F!important;
    }

    .document-results-caption span {
        padding-left: 5px;
        font-size: 18px!important;
        font-weight: 300!important;
        color: #A9ADB1!important;
    }

    .document-results-tools {
        flex: 1;
        display: flex!important;
        flex-direction: row!important;
        align-items: center;
        justify-content: end;
        gap: 10px;
    }

    .document-results-preloader {
        margin-left: 5px !important;
        display: flex;
        align-items: center;

        .document-results-preloader-icon {
            width: 16px !important;
            height: 16px !important;
            border-width: 2px !important;
        }
        .document-results-preloader-text {
            font-size: 12px !important;
        }
    }

    .empty-search-result-message {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 10px;
        padding: 10px;
        font-size: 12px!important;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        border-radius: 4px;
    }
}

//.doc-state-success-indicator {
//    position: absolute;
//    left: 50%;
//    top: 50%;
//    display: block;
//    width: 6px;
//    height: 6px;
//    transform: translate(-50%, -50%);
//    border-radius: 50%;
//    background: #84c35c;
//}

.doc-state-indicator {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    background: transparent;
    padding: 1px 10px 0 10px;
    border-radius: 12px;
    height: 24px;
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
}

.doc-state-indicator.--success {
    color: #74B100;
    border: 1px solid #74B100;
}

.selected-row .doc-state-indicator.--success{
    color: #FFFFFF;
    border: 1px solid #FFFFFF;

    svg path {
        stroke: #FFFFFF!important;
    }
}

.doc-state-indicator.--progress {
    color: rgb(100, 100, 100);
    border: 1px solid rgba(200, 200, 200, 0.25);
    background: rgba(200, 200, 200, 0.25);
    background: linear-gradient(110deg, rgba(200, 200, 200, 0.25) 8%, rgba(255, 255, 255, 0.8) 18%, rgba(200, 200, 200, 0.25) 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.selected-row .doc-state-indicator.--progress {
    color: #FFFFFF;

    svg path {
        fill: #FFFFFF!important;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.download-documents-button {
    background: none;
    border: none;
    outline: none;
    color: #000000;
    text-decoration: underline;
}