.survey-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;

  .survey-form-container {
    margin: 0 auto;
    max-width: 570px;
    width: 100%;
    background: white;
    padding: 40px;

    .survey-form-input-group {
      margin-bottom: 18px;
    }

    .survey-form-select {
      width: 100%;
      padding: 12px 22px;
      border-radius: 5px;
      border: 1px solid #dde3ec;
      background: #ffffff;
      font-size: 16px;
      color: #536387;
      outline: none;
      resize: none;
    }

    .survey-form-input-radio-wrapper {
      margin-bottom: 25px;
    }
    .survey-form-radio-flex {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .survey-form-radio-label {
      font-size: 14px;
      line-height: 24px;
      color: #07074d;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: flex;
      align-items: center;
    }
    .survey-form-input-radio {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .survey-form-radio-checkmark {
      position: relative;
      height: 18px;
      width: 18px;
      background-color: #ffffff;
      border: 1px solid #dde3ec;
      border-radius: 50%;
      margin-right: 5px;
    }
    .survey-form-input-radio:checked ~ .survey-form-radio-checkmark {
      background-color: #6a64f1;
    }
    .survey-form-radio-checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    .survey-form-input-radio:checked ~ .survey-form-radio-checkmark:after {
      display: block;
    }

    .survey-form-radio-label .survey-form-radio-checkmark:after {
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ffffff;
      transform: translate(-50%, -50%);
    }

    .survey-form-input {
      width: 100%;
      padding: 13px 22px;
      border-radius: 5px;
      border: 1px solid #dde3ec;
      background: #ffffff;
      font-weight: 300;
      font-size: 16px;
      color: #07074d;
      outline: none;
      resize: none;
    }
    .survey-form-input::placeholder {
      color: #536387;
    }
    .survey-form-input:focus {
      border-color: #6a64f1;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    }
    .survey-form-label {
      color: #07074d;
      font-size: 14px;
      line-height: 24px;
      display: block;
      margin-bottom: 10px;
    }

    .survey-form-btn {
      text-align: center;
      width: 100%;
      font-size: 16px;
      border-radius: 5px;
      padding: 14px 25px;
      border: none;
      font-weight: 500;
      background-color: #6a64f1;
      color: white;
      cursor: pointer;
      margin-top: 25px;
    }
    .survey-form-btn:hover {
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
    }

  }

}


