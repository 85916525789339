@import "../../../scss/extends.scss";
@import "../../../scss/default_variables.scss";

.statistics-page-container{
  @extend .common-flex-column-container;
  gap: $gap-default;

}
.statistics-page-row-container{
  @extend .common-flex-row-container;
  padding: $padding-row-container-default;
  gap: $gap-default;
}
