.trip-builder-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;

  .trip-builder-form-header {
    padding: 10px 20px;
    padding-top: 0;

    .order-error-message {
      width: 90%;
      text-align: center;
      margin: 10px auto;
      padding: 10px;
      font-size: 12px!important;
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      border-radius: 4px;
    }

    .trip-builder-form-company-check {
      display: flex;
      flex-direction: row;
      gap: 10px;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }

    .trip-builder-form-company-check-error-message {
      position: relative;
      width: 100%;
      text-align: center;
      padding: 10px 32px;
      font-size: 12px!important;
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      border-radius: 4px;
    }

    .selected-company-details {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .company-item {
        display: flex;
        flex-direction: row;
        outline: none;
        background: none;
        margin: auto;
        width: 100%;
        justify-self: start;
        align-self: start;
        border-radius: 5px;
        border: 1px solid #efefef;
        margin-top: 5px;
        padding: 6px 0;
        background-color: #FFFFFF;
        box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);


        &:last-child {
          margin-bottom: 5px;
        }

        .company-item-icon {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 100%;
          margin-top: 5px;
        }

        .company-item-details {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;

          .company-item-details-row {
            flex: 1;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            justify-content: start;

            &:first-child {
              align-items: end;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:last-child {
              align-items: start;
            }

            .company-item-name {
              font-size: 12px;
              color: #242A2A;
              font-weight: 500;
              font-size: 12px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              padding: 0 5px 0 0;
            }

            .company-item-inn {
              font-size: 11px;
              color: #8E8E90;
              font-weight: 500;
            }

          }
        }

        .company-item-tools {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }

  .trip-builder-form-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .trip-builder-form-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: calc(-300px + 100vh);
    overflow: auto;
    padding: 10px 0;
  }

  .trip-builder-preloader {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 50% auto auto auto;

    span {
      font-size: 12px;
    }
  }

  .trip-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;

    >*:nth-child(1) {
      flex: 1;
      padding-left: 54px;
    }
    >*:nth-child(2) {
      width: 54px;
      padding-right: 10px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }

  .trip-add-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px 0;
  }

  .trip-builder-form-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    justify-content: center;
    padding: 10px 20px 20px 10px;
  }

}

.trip-builder-form-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 0 20px;
}



.order-summary-info {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 5px 40px;

  .order-summary-info-item {
    display: flex;
    flex-direction: row;
    gap: 5px;

    span:nth-child(1) {
      font-size: 12px;
    }

    span:nth-child(2) {
      position: relative;
      flex: 1;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #c5c5c5, #FFFFFF);
        background-size: 8px 2px, 8px 100%;
      }
    }

    span:nth-child(3) {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.order-tools {
  display: flex;
  align-items: center;
  padding: 10px 40px;
  justify-content: center;
}


.trip-status {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 5px;
  padding: 0 5px;

  .trip-status-text {
    display: flex;
    align-items: center;
    padding: 1px 10px 0 10px;
    border-radius: 4px;
    color: #898989;
    border: 1px solid #898989;
    font-size: 12px;
    z-index: 1000;
    pointer-events: none;

    &.info-status {
      color: #cd7402;
      border: 1px solid #cd7402;
    }

    &.error-status {
      color: #b12f00;
      border: 1px solid #b12f00;
    }
  }
}


.booking-pending-message,
.issue-pending-message {
  flex: none;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  padding-top: 0;
}

.passengers-error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  text-align: center;
  margin: 10px auto;
  padding: 10px;
  font-size: 12px!important;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 4px;

  span:nth-child(1) {
    flex: 1;
  }

}

.booking-error-message {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  padding: 10px;
  font-size: 12px!important;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-radius: 4px;

  span:nth-child(1) {
    flex: 1;
    text-align: center;
  }
}

.booking-complete-message {
  width: 90%;
  text-align: center;
  margin: 10px auto;
  padding: 10px;
  font-size: 12px !important;
  color: #04850f;
  background-color: #cdffd5;
  border-color: #ffeeba;
  border-radius: 4px;
}