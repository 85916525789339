
.md-scroll-viewer {
  position: relative;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .top-shadow {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 14px;
    background: none;
    background-repeat: no-repeat;
    background-size: 100% 14px, 100% 40px;
    z-index: 900;
    pointer-events: none;
  }

  &.--shadowed-top .top-shadow {
    background: linear-gradient( rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0) ) center bottom,
    radial-gradient( farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) ) center bottom;
  }


  .scrollable-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }


  .bottom-shadow {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14px;
    background: none;
    background-repeat: no-repeat;
    background-size: 100% 14px, 100% 40px;
    transform: rotate(180deg);
    z-index: 900;
    pointer-events: none;
  }

  &.--shadowed-bottom .bottom-shadow {
    background: linear-gradient( rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0) ) center bottom,
    radial-gradient( farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) ) center bottom;
  }

}