.user-details-form {
  display: flex;
  flex-direction: column;

  .user-details-form-sections {
    padding: 0 40px 20px 40px;

    .user-details-form-section-title {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 2px;
      color: black;
      font-size: 14px;
      font-weight: 400;
      border: none;
      background: none;
      outline: none;
      font-style: oblique;
    }
    .user-details-form-section-content {
      opacity: 1;
      transition: all 200ms linear;
      will-change: opacity, max-height;
      padding: 0;

      .user-details-form-fields-group {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }
  }

  input {
    display: block;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &::-webkit-input-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::-moz-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::-ms-input-placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &::placeholder {
      font-style: italic;
      color: #95989a;
      opacity: 1;
    }

    &:focus {
      color: #212529;
      background-color: #ffffff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }
  }

  .error-message {
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    font-size: 12px!important;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
    border-radius: 4px;
  }

  .user-gender-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .user-company-list {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;

    .company-list-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      background: #f7f7f7;
      padding: 10px;
      border-radius: 5px;
      box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.05);

      .company-list-item-info {
        display: flex;
        flex-direction: row;
        gap: 5px;
        font-size: 12px;
        font-weight: 500;

        .company-name {
          max-width: 200px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .remove-company {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: end;
        }
      }

      .company-list-item-roles {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-wrap: wrap;
      }
    }
  }
}