.md-menu {
  position: relative;
  padding: 0;
  border: none;

  &.--disabled {
    pointer-events: none;
    opacity: 0.75;
  }

  .md-menu-header {
    max-width: 300px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 0 10px;
    list-style: none;
    list-style-type: none;
    user-select: none;
    font-size: 12px;
    color: #777E90;
    font-weight: 500;
    z-index: 5;

    &::-webkit-details-marker {
      display: none;
    }

    &:focus {
      outline: none;
    }

    .md-menu-header-label {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }
  }

  .md-menu-dropdown-list {
    position: absolute;
    top: -5px;
    right: 0;
    margin: 0;
    padding: 40px 0 0 0 !important;
    min-width: 100%;
    box-sizing: border-box;
    z-index: 2;
    background: #FAFAFA;
    border-radius: 6px;
    list-style: none;
    //box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    box-shadow: 2px 7px 16px 0px rgba(0, 0, 0, 0.1), 0px 24px 38px 3px rgba(0, 0, 0, 0.07), 0px 9px 46px 8px rgba(0, 0, 0, 0.06);

    >ul {
      //max-height: 320px;
      overflow: auto;
      padding: 0 5px 5px 5px !important;

      >li {
        padding: 0;
        margin: 1px 0 0 0;
        background: #f5f5f5;
      }

      >li:hover {
        background: #efefef;
      }
    }
  }

  &[data-behaviour="none"] .md-menu-dropdown-list > ul > li {
    background: transparent!important;
    cursor: default!important;
  }
}

.md-menu-item {
  cursor: pointer;
  max-width: 300px;
  font-size: 12px;
  text-align: center;
  color: #777E90;
  font-weight: 500;
  text-wrap: nowrap;
  padding: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}


