.train-component {

  .train-component-header {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    width: 500px;
    min-height: 100px;
    border-radius: 5px;
    border: 1px solid #efefef;
    box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    z-index: 1;

    .train-component-header-first-line {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .air-company {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: start;

        span {
          text-transform: uppercase;
          padding: 2px 5px;
          background-color: #FFFFFF;
          color: #000000!important;
          font-size: 10px!important;
          border-radius: 5px;
          -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
          -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
          box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
        }
      }

      .price {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: end;

        span {
          padding: 2px 5px;
          background-color: #FFFFFF;
          color: #000000!important;
          font-size: 12px!important;
          text-transform: inherit!important;
          border-radius: 5px;
          -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
          -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
          box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2) inset;
        }
      }
    }

    .train-component-header-second-line {
      position: relative;
      display: flex;
      flex-direction: row;
      flex: 1;
      margin-top: 5px;

      .department-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding-right: 5px;
        width: 100px;

        .department-airport-code {
          font-weight: 500;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }

        .department-airport-city {
          font-size: 12px;
          color: #A0A1A6;
        }

        .department-time {
          font-size: 12px;
          color: #3a3f52;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      .train-path-info {
        flex: 1;
        display: flex;
        gap: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        .train-track {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 20px;
          margin-top: -6px;

          hr {
            flex: 1;
            border: none;
            border-top: 1px dashed #939393;
            color: #fff;
            background-color: #fff;
            height: 1px;
            width: 1000%;
          }

          .train-track-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            margin-top: 12px;
          }
        }

        .train-connections {
          font-size: 12px;
          font-weight: 500;
          background-color: rgba(249, 105, 0, 0.15);
          padding: 0px 8px;
          border-radius: 10px;
          color: #bd5000;
          margin-top: 5px;
        }

        .train-time {
          font-size: 10px;
          font-weight: 500;
          background-color: #DDDFEE;
          padding: 0px 8px;
          border-radius: 10px;
          border: 1px solid rgba(62, 92, 184, 0.5);
          color: rgba(62, 92, 184, 0.7);
          margin-top: 5px;
        }
      }

      .arrival-info {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: center;
        padding-left: 5px;
        width: 100px;

        .arrival-airport-code {
          font-weight: 500;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
        }

        .arrival-airport-city {
          font-size: 12px;
          color: #A0A1A6;
        }

        .arrival-time {
          font-size: 12px;
          color: #3a3f52;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
  }

  .train-component-details {
    position: relative;
    display: flex;
    padding: 5px;
    flex-direction: column;
    background-color: #fbfbfb;
    transform: translateY(-50px);
    opacity: 1;
    width: 94%;
    margin-left: 3%;
    min-height: 100px;
    border: 1px solid #efefef;
    border-top-width: 0;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.05) inset;

    .train-tariff-switcher {
      display: flex;
      flex-direction: row;
      gap: 5px;
      padding: 10px 10px 10px 10px;
      width: 100%;
      overflow-x: auto;

      button {
        flex: 1;
      }
    }

    .train-tariff-info {
      display: flex;
      flex-direction: column;
      padding: 10px 20px 5px 20px;
      border-bottom: 1px solid #e9e9e9;

      .train-tariff-info-item {
        display: flex;
        flex-direction: row;
        gap: 5px;

        span:nth-child(1) {
          font-size: 12px;
        }

        span:nth-child(2) {
          position: relative;
          flex: 1;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #c5c5c5, #FFFFFF);
            background-size: 8px 2px, 8px 100%;
          }
        }

        span:nth-child(3) {
          font-size: 12px;
        }
      }
    }

    .train-info-section {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid #e9e9e9;
      }

      .train-roadmap {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 20px 20px 20px;

        .train-roadmap-item {
          display: flex;
          flex-direction: column;
          margin-top: 15px;

          .train-roadmap-item-start-node {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 20px;

            .train-roadmap-item-start-node-button {
              border: none;
              outline: none;
              width: 16px;
              height: 16px;
              border-radius: 10px;
              background-color: #FFFFFF;
              border: 1px solid #dddddd;
              box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.5);

              &.--train-begin {
                border: 2px solid #0077FF;
              }

              &.--active {
                border: 2px solid rgb(116, 177, 0);
                animation:pulse 1500ms infinite;
              }

              @keyframes pulse{
                0%{
                  box-shadow: rgba(116, 177, 0, 0.8) 0 0 0 0;
                }
                75%{
                  box-shadow: rgba(116, 177, 0, 0) 0 0 0 16px;
                }
              }
            }

            .train-roadmap-item-start-node-location {
              padding: 0 10px 0 15px;
              font-weight: 500;
              font-size: 12px;
              text-transform: capitalize;
            }

          }

          .train-roadmap-item-content {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 10px;
            padding-left: 30px;
            padding-top: 10px;
            padding-bottom: 10px;

            &:before {
              content: '';
              position: absolute;
              display: block;
              top: 0;
              left: 8px;
              width: 1px;
              //background: linear-gradient(to bottom, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #dddddd, #FFFFFF);
              //background-size: 2px 16px, 2px 100%;
              background-color: #eeeeee;
              height: 100%;
            }
          }

          .train-roadmap-item-end-node {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 20px;

            .train-roadmap-item-end-node-button {
              border: none;
              outline: none;
              width: 16px;
              height: 16px;
              border-radius: 10px;
              background-color: #FFFFFF;
              border: 1px solid #dddddd;
              box-shadow: 0px 2px 12px -4px rgba(0, 0, 0, 0.5);

              &.--train-end {
                border: 2px solid #00B421;
              }

              &.--active {
                border: 2px solid rgb(116, 177, 0);
                animation:pulse 1500ms infinite;
              }

              @keyframes pulse{
                0%{
                  box-shadow: rgba(116, 177, 0, 0.8) 0 0 0 0;
                }
                75%{
                  box-shadow: rgba(116, 177, 0, 0) 0 0 0 16px;
                }
              }
            }

            .train-roadmap-item-end-node-location {
              padding: 0 10px 0 15px;
              font-weight: 500;
              font-size: 12px;
              text-transform: capitalize;
            }

          }


          .train-roadmap-validation {
            position: relative;
            display: flex;
            flex-direction: row;

            &:before {
              content: "";
              position: absolute;
              display: block;
              top: -12px;
              left: 8px;
              width: 1px;
              background: linear-gradient(to bottom, transparent 50%, #FFFFFF 50%), linear-gradient(to right, #dddddd, #FFFFFF);
              background-size: 2px 16px, 2px 100%;
              bottom: -12px;
            }

            .train-roadmap-validation-node {
              position: relative;
              width: 30px;

              .train-roadmap-validation-node-button {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: -3px;
                border: none;
                outline: none;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: #FFFFFF;
                padding: 3px;

                svg {
                  display: block;
                }
              }
            }

            .train-roadmap-validation-message {
              position: relative;
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: start;
              gap: 5px;
              border: 1px solid #F5F5F5;
              background-color: #F7F7F7;
              border-radius: 4px;
              padding: 10px 20px;

              .train-roadmap-validation-message-title {
                color: #F96E00;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
              }

              .train-roadmap-validation-message-content {
                position: relative;
                color: #838383;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                list-style: disc;
                padding-left: 15px;
              }
            }
          }

        }

      }

      .passengers-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .passenger-item {
          display: flex;
          flex-direction: row;
          outline: none;
          background: none;
          margin: auto;
          width: 100%;
          max-width: 420px;
          height: auto;
          justify-self: start;
          align-self: start;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #f2f2f2;
          border-top-width: 0;
          border-left-width: 0;
          border-right-width: 0;
          margin-top: 5px;
          background-color: #FFFFFF;
          box-shadow: 0px 8px 12px -4px rgba(0, 0, 0, 0.1);
          padding: 5px;

          .passenger-item-icon {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: start;
          }

          .passenger-item-details {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;

            .passenger-item-details-row {
              flex: 1;
              display: flex;
              flex-direction: row;
              gap: 5px;
              align-items: center;
              justify-content: start;

              &:first-child {
                align-items: end;
              }

              &:last-child {
                align-items: start;
              }

              .passenger-fio {
                font-size: 12px;
                color: #242A2A;
                font-weight: 500;
              }

              .passenger-dob {
                font-size: 11px;
                color: #8E8E90;
              }

            }


            .passport-item {
              position: relative;
              display: flex;
              flex-direction: row;
              gap: 10px;
              align-items: center;

              &.--expired:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 105%;
                height: 1px;
                background-color: rgba(255, 0, 0, 0.7);
              }

              .passport-item-type {
                color: #afafaf;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                //text-transform: uppercase;
                font-size: 12px;
              }

              .passport-item-number {
                flex: 1;
                color: #000000;
                text-align: left;
                font-size: 11px;
              }

              .passport-item-expiry {
                display: flex;
                align-items: center;
                justify-content: end;
                color: #8E8E90;
                text-transform: uppercase;
                font-size: 11px;
                text-align: right;
              }
            }

          }

        }
      }
    }
  }
}

.train-component[open] .train-component-details {
  transform: translateY(0);
  opacity: 1;
}