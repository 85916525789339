@import "../../scss/mixins";

body {
  font-family: 'Roboto', sans-serif;
  color: #3a3f52;
  background-image: linear-gradient(270deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%);
}

h2 {
  font-size: 44px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

h5 {
  font-size: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #53627C;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

b {
  font-weight: 700;
}



.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: 100vh;
  background: none!important;
}



/* page header */
.page-header {
  display: flex;
  align-items: center;
  position: relative;
  background-image: linear-gradient(120deg, rgb(253, 251, 251) 0%, rgb(235, 237, 238) 100%) !important;
  height: 40px;
  padding: 0;

  .header-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: calc(1.5rem * .5);
    padding-left: calc(1.5rem * .5);
    margin-right: auto;
    margin-left: auto;

    ul.header-navbar-left,
    ul.header-navbar-right {
      flex: 1;
      flex-direction: row;
      padding: 0 !important;
      align-items: center;

      li a,
      li button {
        background: none;
        border: none;
        outline: none;
        color: #777E90 !important;
        font-weight: 500 !important;
      }
    }

    ul.header-navbar-left {
      display: flex;
      justify-content: start !important;

      a,
      button {
        font-size: 10px !important;
      }
    }

    ul.header-navbar-right {
      display: flex;
      justify-content: end !important;

      a,
      button {
        font-size: 12px !important;
      }
    }


    .company-selection-container {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .company-selection-search-bar {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 5px 0;
      height: 26px;
      width: 100%;
      background-color: #FAFAFA;
      padding: 0 5px;


      input {
        flex: 1;
        width: 100%;
        min-width: 240px;
        height: 100%;
        background-color: rgba(180, 180, 180, 0.1);
        border: none;
        outline: none;
        font-size: 12px;
        padding: 0  10px 0 26px;
        color: #727272;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.025) inset;
        border-radius: 6px;

        &::placeholder {
          color: #b1b1b1;
          font-style: italic;
        }
      }
    }

    .company-selection-search-results {
      flex: 1;
      position: relative;
      width: 100%;
      max-height: 260px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .company-selection-search-results-title {
        font-size: 10px;
        color: #b1b1b1;
        font-style: italic;
        padding: 0 10px;
      }

      .company-selection-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0;
        align-items: start;
        min-height: 60px;
        padding: 5px;
        cursor: pointer;

        .company-selection-item-code {
          font-size: 10px;
          color: #b1b1b1;
        }

        .company-selection-item-name {
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .loading-results-message {
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        font-size: 10px;
        color: #A0A1A6;
      }

      .company-selection-search-no-results-message {
        font-size: 11px;
        color: #b1b1b1;
        font-style: italic;
        padding: 0 10px;
        text-align: center;
        margin: 5px 0;
      }
    }

    .user-data {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      .user-role {
        padding: 2px 6px;
        font-size: 8px;
        background-color: #CDF8DA;
        color: #42764F;
        border-radius: 10px;
      }
    }
  }
}



/* page navbar */
.page-navbar {
  display: flex;
  align-items: center;
  position: relative;
  height: 70px;
  background-color: #FFFFFF;
  color: #777E90;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  box-shadow: 0px -20px 16px -20px rgba(0, 0, 0, 0.1);

  &.--fixed {
    position: fixed;
    top: 0 !important;
    right: 0;
    left: 0;
    z-index: 1030;
    box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.19);
  }

  .page-navbar-desktop {

    .navbar-container {
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: calc(1.5rem * .5);
      padding-left: calc(1.5rem * .5);
      margin-right: auto;
      margin-left: auto;

      .navbar-logo-desktop-container {
        flex: 0.5;

        a {
          position: relative;
          filter: brightness(1.5);

          svg {
            height: 50px;
          }
        }
      }

      ul {
        flex: 1 !important;
        align-items: center;
        justify-content: center;
        gap: 16px;

      }

      ul li {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: .05em;
        margin-top: 0;
        position: relative;
        color: #777E90;
        cursor: pointer;

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      ul li.--active {
        color: #000000;
        font-weight: 600 !important;
      }

      ul li.--active:before {
        content: "";
        position: absolute;
        display: block;
        width: 5px;
        height: 1px;
        left: -8px;
        top: 50%;
        background: #000000;
      }

      ul li.--active:after {
        content: "";
        position: absolute;
        display: block;
        width: 5px;
        height: 1px;
        right: -8px;
        top: 50%;
        background: #000000;
      }

      .lc-button-container {
        flex: 0.5;
        display: flex;
        justify-content: end;
      }

      .lc-button,
      .feedback-button {
        background-color: #3E5CB8;
        color: #FFFFFF !important;
        outline: none;
        border: none;
        padding: 10px 20px;
        border-radius: 20px;
        font-size: 12px;
        font-weight: 400;
        height: 40px;
        text-decoration: none;
      }

    }
  }

  .page-navbar-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 20px;

    .page-navbar-mobile-left-side {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: start;

      .navbar-logo-mobile {
        height: 50px;
        width: 60px;

        svg.original-logo {
          display: block;
          filter: brightness(1.5);
        }

        //svg.white-logo {
        //  display: none;
        //}
      }
    }

    .page-navbar-mobile-middle {
      display: flex;
      align-items: center;
      color: #000000;
      font-weight: 500;
    }

    .page-navbar-mobile-right-side {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: end;

      .mobile-menu-toggle-button {
        border: none;
        outline: none;
        background: none;
        position: relative;
        width: 40px;
        height: 40px;
        z-index: 1000;
        font-size: 38px;
        background-color: transparent;
        z-index: 1000;

        i .icon-burger {
          top: 13px;
          left: 18px;
          width: 16px;
          height: 16px;
          position: absolute;

          span {
            top: 0;
            left: 0;
            width: 16px;
            height: 1px;
            margin: 4px 0;
            display: block;
            position: relative;
            background: #000000;
            transition: width 0.25s ease-in-out;
          }

          span:nth-child(1) {
            transition-delay: 0s;
          }

          span:nth-child(2) {
            transition-delay: .125s;
          }

          span:nth-child(3) {
            transition-delay: .25s;
          }
        }

        i .icon-close {
          top: 12px;
          left: 20px;
          width: 16px;
          height: 16px;
          position: absolute;
          transform: rotate(45deg);

          span {
            display: block;
            transition: 0.25s ease-in-out;
            background: #000000;
          }

          span:nth-child(1) {
            top: 4px;
            left: 7px;
            height: 0;
            width: 1px;
            position: absolute;
            transition-delay: 0s;
          }

          span:nth-child(2) {
            left: 0;
            top: 11px;
            width: 0;
            height: 1px;
            position: absolute;
            transition-delay: .25s;
          }
        }
      }
    }
  }

  &.--mobile-menu-opened {

    .page-navbar-mobile .page-navbar-mobile-left-side .navbar-logo-mobile {
      z-index: 1000;
    }

    //.page-navbar-mobile .page-navbar-mobile-left-side .navbar-logo-mobile svg.original-logo {
    //  display: none;
    //}

    //.page-navbar-mobile .page-navbar-mobile-left-side .navbar-logo-mobile svg.white-logo {
    //  display: block;
    //}

    .page-navbar-mobile .page-navbar-mobile-left-side .navbar-logo-mobile svg.original-logo {
      z-index: 1000;
    }

    .page-navbar-mobile .page-navbar-mobile-right-side .mobile-menu-toggle-button i .icon-burger span {
      width: 0;
      background: #000000;
    }

    .page-navbar-mobile .page-navbar-mobile-right-side .mobile-menu-toggle-button .icon-close span:nth-child(1) {
      height: 15px;
      transition-delay: .625s;
    }

    .page-navbar-mobile .page-navbar-mobile-right-side .mobile-menu-toggle-button .icon-close span:nth-child(2) {
      width: 15px;
      transition-delay: .375s;
    }
  }
}



/* mobile menu */
.mobile-menu {
  display: none;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  position: fixed;
  background-image: linear-gradient(180deg, rgb(253, 251, 251) 0%, rgb(215 215 215) 100%);
  -moz-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 1.15s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 1.15s cubic-bezier(0.19, 1, 0.22, 1);

  &.--opened {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }

  .mobile-menu-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    width: 540px;

    .nav-experience {
      width: auto;
      float: left;
      height: 100%;
      position: relative;
      padding-right: 70px;
      color: #ffffff;

      ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
        unicode-bidi: isolate;

        li {
          color: rgba(0, 0, 0, 0.2);
          font-size: 16px;
          padding-left: 20px;
          list-style: decimal-leading-zero;
          margin-bottom: 20px;

          &.--active {
            //color: #FFFFFF;
            color: rgb(0, 0, 0);
          }

          a {
            font-size: 20px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.5);
            display: inline-block;
            -webkit-transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            text-decoration: none;
          }

          &.--active a {
            //color: #99cc33;
            //color: rgb(112 140 225);
            color: #000000;
            font-weight: 400;
          }

          &:not(.--active) a:hover {
            color: rgb(0, 0, 0) !important;
          }
        }
      }
    }

    .nav-main {
      width: 40%;
      min-height: 100%;
      float: right;
      padding-left: 50px;
      position: relative;
      border-left: 1px solid #57595a;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      ul {

        li {
          border: none;
          margin-bottom: 12px;

          a,
          button {
            border: none;
            outline: none;
            background: none;
            font-size: 16px;
            font-weight: 300;
            color: #999999;
            display: inline-block;
            -webkit-transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
            transition: color 0.3s cubic-bezier(0.19, 1, 0.22, 1);
          }

          a:hover,
          button:hover {
            color: rgb(0, 0, 0) !important;
          }
        }
      }
    }
  }
}



.page-container {
  min-height: 100%;
  flex-grow: 1;
}


/* footer */
footer {
  background-color: #292c31;
  padding: 60px 0;
  color: #62656a;

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    margin-right: auto;
    margin-left: auto;

    .footer-logo {
      position: relative;
      width: 100px;
      height: 50px;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-right: 0;
      margin-bottom: 50px;

      svg {
        height: 100%;
      }
    }

    .footer-block {

      .footer-block-title {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      ul.footer-block-items {

        li {
          max-width: 150px;
          opacity: .8;
          font-size: 12px;
          font-weight: 300;
          line-height: 175%;
          font-style: normal;
          position: relative;
          color: #ffffff;
          white-space: nowrap;
          letter-spacing: 0.010em;
        }
      }
    }
  }

}





.circle-spinner {
  animation: circle-spinner-rotate 2s linear infinite;
  min-width: 10px;
  min-height: 10px;

  & circle {
    stroke: #000000;
    stroke-linecap: round;
    animation: circle-spinner-dash 1.5s ease-in-out infinite;
  }

}

@keyframes circle-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle-spinner-dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}










.chat-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  transition: all 250ms ease-out;
  border-radius: 50%;
  opacity: 0;
  //background: -webkit-linear-gradient(to right, #ece9e6, #ffffff); /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(to right, #ece9e6, #ffffff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  //background-repeat: no-repeat;
  //background-attachment: fixed;
  background-color: #FFFFFF;
  opacity: 0.6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
  z-index: 1000;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    opacity: 1;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 3pt 2pt rgba(14, 200, 121, 0.3);
  }

  &.expand {
    width: 400px;
    max-height: 85vh;
    height: 680px;
    border-radius: 5px;
    cursor: auto;
    opacity: 1;
    overflow: hidden;

    .chat-button-icon {
      display: none;
    }
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    border-radius: 50%;
    transition: all 250ms ease-out;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.enter {
      opacity: 1;
      border-radius: 0;
      width: auto;
      height: auto;
    }
  }
}


@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}

@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}










/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  h2 {
    font-size: 34px !important;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  .title-block h2, p {
    text-align: left;
    padding: 0px 20px;
  }

  footer {
    padding: 60px 25px;
  }
  footer h5 {
    font-size: 18px;
    margin-top: 30px;
  }
  footer li {
    font-size: 16px;
    margin-bottom: 15px;
  }
  footer p {
    font-size: 16px;
  }
  footer i {
    font-size: 30px;
    margin-right: 20px;
  }
  footer small {
    float: left;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {

}

@media (max-width: 991px) {
  .nav-right {
    float: left;
  }
  .page-navbar ul li {
    border-bottom: 1px solid #EEE;
  }
  .navbar-nav {
    width: 100%;
  }
  .navbar-nav .nav-link {
    padding: 20px 0;
    text-align: left;
  }

  .page-navbar ul li {
    font-size: 13px;
  }

  .icon-mobile {
    font-size: 18px;
    float: right;
  }

  .navbar-collapse {
    border-bottom: 1px solid #efefef;
  }

  .nav-logo-desktop {
    display: none;
  }

  .page-header {
    display: none;
  }

  .page-navbar {
    position: fixed;
    top: 0 !important;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .page-navbar.--shadowed {
    box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0.19);
  }

  .mobile-menu {
    display: flex;
  }
}


/*
  Device = Desktops
  Screen = 1281px to higher resolution desktops
*/
@include desktop {

}

/*
  Device = Laptops, Desktops
  Screen = from 1025px to 1280px
*/
@include laptop {

}

/*
  Device = Tablets, Ipads (portrait)
  Screen = from 768px to 1024px
*/
@include tabletPortrait {

}

/*
  Device = Tablets, Ipads (landscape)
  Screen = from 768px to 1024px
*/
@include tabletLandscape {

}

/*
  Device = Low Resolution Tablets, Mobiles (Landscape)
  Screen = from 481px to 767px
*/
@include mobilesLandscape {
  //.mobile-menu-nav-container {
  //  display: flex;
  //  flex-direction: column;
  //  width: auto;
  //}
  .nav-main {
    padding-top: 0px;
    padding-left: 60px;
    min-height: auto;
    //border-left: none;
    //border-top: 1px solid #57595a;
  }
  .nav-main ul {
    position: relative !important;
    transform: none !important;
    top: auto !important;
  }
  .mobile-menu .mobile-menu-nav-container .nav-experience ol li {
    margin-bottom: 15px;
  }
  .mobile-menu .mobile-menu-nav-container .nav-experience ol li a {
    font-size: 18px;
  }
  .mobile-menu .mobile-menu-nav-container .nav-main ul li a,
  .mobile-menu .mobile-menu-nav-container .nav-main ul li button {
    font-size: 14px;
  }
}

/*
  Device = Most of the Smartphones Mobiles (Portrait)
  Screen = from 320px to 479px
*/
@include mobilesPortrait {
  .mobile-menu .mobile-menu-nav-container {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .mobile-menu .mobile-menu-nav-container .nav-experience {
    padding-bottom: 20px;
  }
  .mobile-menu .mobile-menu-nav-container .nav-main {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-left: 0;
    min-height: auto;
    min-width: 100%;
    border-left: none;
    border-top: 1px solid #57595a;
  }
  .mobile-menu .mobile-menu-nav-container .nav-main ul {
    position: relative !important;
    transform: none !important;
    top: auto !important;
  }
  .mobile-menu .mobile-menu-nav-container .nav-experience ol li {
    margin-bottom: 15px;
  }
  .mobile-menu .mobile-menu-nav-container .nav-experience ol li a {
    font-size: 18px;
  }
  .mobile-menu .mobile-menu-nav-container .nav-main ul li a,
  .mobile-menu .mobile-menu-nav-container .nav-main ul li button {
    font-size: 14px;
  }
}


@media (min-width: 992px) {
  .header-container,
  .navbar-container,
  .footer-container {
    max-width: 960px;
  }
  .page-navbar {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .page-navbar .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .page-navbar .navbar-nav {
    flex-direction: row;
  }
  .page-navbar .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .icon-mobile {
    display: none;
  }
  .page-navbar .page-navbar-mobile {
    display: none;
  }

  .footer-container {
    flex-direction: row;
  }

  .footer-logo {
    margin-right: 100px!important;
  }
}

@media (min-width: 1200px) {
  .header-container,
  .navbar-container,
  .footer-container {
    max-width: 1140px;
  }

  .footer-container {
    flex-direction: row!important;
  }

  .footer-logo {
    margin-right: 100px!important;
  }
}

@media (min-width: 1400px) {
  .header-container,
  .navbar-container,
  .footer-container {
    max-width: 1320px;
  }

  .footer-container {
    flex-direction: row!important;
  }

  .footer-logo {
    margin-right: 100px!important;
  }
}