@import "../../../scss/mixins";

.change-password-form {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: #fff;

  .change-password-form-header {
    text-align: center;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 400;
  }

  .change-password-form-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .change-password-form-error-message {
      p {
        width: 100%;
        text-align: center;
        margin: 0 0 10px 0;
        padding: 10px;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        border-radius: 4px;
        font-size: 12px;
        color: #721c24;
      }
    }
  }
}


@media (max-width: 991px) {
  .change-password-form {
    margin-top: 100px;
  }
}



.user-checking-message {
  margin: 20px auto auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 40px;
  background: #FFFFFF;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  max-width: 300px;
}

.change-password-complete-message {
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 40px;
  background: #FFFFFF;
  border-radius: 2px;
  text-align: center;
  font-size: 14px;
  max-width: 500px;
}