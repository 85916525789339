@import "../../../scss/extends.scss";
@import "../../../scss/default_variables.scss";

.support-page-container {
  @extend .common-flex-column-container;
  gap: $gap-default;
}

.support-page-row-item {
  @extend .common-flex-row-container;
  gap: $gap-default;
  padding: $padding-row-container-default;

  div[typeof="button"] {
    p {
      color: white
    }
  }
}

.support-info-block {
  @extend .common-flex-column-container;

  div[typeof="label"] {
    font-size: 0.9rem;
  }

  div[typeof="info"] {
    font-size: 1rem;
  }
}

.support-vertical-border {
  border-left: 1px solid lightgrey;
  padding: $padding-row-container-default;
}

.support-page-button {
  align-self: center;
  p {
    color: white;
  }
}

.support-user-form-container {
  @extend .common-flex-column-container;
  border: 1px solid black;
  border-radius: 10px;
  max-width: 600px;
  align-self: center;
  margin-top: 25px;
  background-color: white;

  .support-form-row-item {
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    div[typeof="support-form-label"] {
      //width: 200px;
      //text-align: end;
      //background-color: #01b501;
      p {
        width: 180px;
        text-align: right;
        font-size: 1rem;
      }
    }

    div[typeof="button"] {
      p {
        color: white
      }
    }


    div[typeof ="support-form-input"] {
      width: 250px;

      select {
        //border-color: lightgrey!important;
        background-color: white;
        width: 220px;
        height: 35px;
        border-radius: 5px;
        color: $secondary-color;
        font-size: 0.9rem;


        option {
          color: gray;
        }

        &:focus {
          //border-color: blue!important;
        }
      }

    }
  }
}